import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import DatePicker from 'components/Form/DatePicker';
import { DATE } from 'constants';
import { isSameDayBeforePickupHours } from 'utils/date';
import ResponsiveListbox from 'components/Form/ResponsiveListbox';

const GMI_TIME_FORMAT = 'HH:mm';

// Suggest "9:00 am"
const SUGGESTED_TIME = '09:00'; // option values are always in this format, regardless of regional format

class DateTimeFieldset extends Component {
  constructor(props) {
    super(props);

    const today = utils.gmi.getCurrentDateTimeObj();

    this.state = {
      minDate: today.toDate(),
      maxDate: today.add(1, 'years').toDate(),
      lastUpdated: Date.now(),
      locationId: null,
      startDate: null,
      endDate: null,
    };
  }

  handleDateChange = (date) => {
    const { onDateChange, dateName } = this.props;
    return onDateChange(date, dateName);
  };

  handleTimeChange = (time) => {
    const { onTimeChange, timeName } = this.props;
    time && onTimeChange(time, timeName);
  };

  static getDerivedStateFromProps({ locationId, startDate, endDate, dateName }, state) {
    switch (true) {
      case startDate !== state.startDate && dateName === 'pickupDate':
        return { startDate, lastUpdated: Date.now() };
      case endDate !== state.endDate && dateName === 'returnDate':
        return { endDate, lastUpdated: Date.now() };
      case locationId !== state.locationId:
        return { locationId, lastUpdated: Date.now() };
      default:
        return null;
    }
  }

  componentDidMount() {
    this.handleGetBranchHours();
  }

  componentDidUpdate(prevProps) {
    const { locationId } = this.props;
    prevProps.locationId !== locationId && locationId && this.handleGetBranchHours();
  }

  handleGetBranchHours = () => {
    //  If we dont have hours for store ID - get them
    const { locationId, hoursForStore, getBranchHours } = this.props;
    !hoursForStore && locationId && getBranchHours(locationId);
  };

  /**
   * This function generates a list of time slots between currentDate and endDate,
   * incrementing by timeInterval (in minutes).
   */
  getHours = (currentDate, endDate, timeInterval) => {
    const hoursList = [];
    const currentTime = currentDate.clone();

    while (currentTime.isBefore(endDate)) {
      hoursList.push(currentTime.clone());
      currentTime.add(timeInterval, 'minutes');
    }

    return hoursList;
  };

  /**
   * This function processes the hoursList (generated from getHours) and
   * categorizes the time slots based on availability (OPEN, AFTER HOURS, UNAVAILABLE, or CLOSED).
   * @param {*} hoursList - The list of time slots (generated from getHours).
   * @param {*} eligibility - Location availability details (standard hours, after-hours, etc.).
   * @param {*} timeType - Determines whether the function is handling pickup or return times.
   * @param {*} locationTimezone - The timezone of the location.
   * @param {*} isSameDayBPH - Checks if the return time is before the pickup time (for same-day returns).
   * @param {*} afterHoursProps - UI content to be displayed for after-hours Tooltip
   * @param {*} optionsGroups - Stores categorized time options (open, closed, after-hours).
   * @param {*} selectsStart - true if the user is selecting a pickup time, false if selecting a return time.
   */
  generateTimeOptions = (
    hoursList,
    eligibility,
    timeType,
    locationTimezone,
    isSameDayBPH,
    afterHoursProps,
    optionsGroups,
    selectsStart
  ) => {
    const getLastOptionsGroup = () => optionsGroups[optionsGroups.length - 1];

    hoursList.forEach((time) => {
      const availabilityStatus = utils.gmi.isLocationAvailableByTime(eligibility, time, timeType, locationTimezone);
      const isUnavailable = availabilityStatus === DATE.AVAILABILITY_STATUS.UNAVAILABLE;
      const isAfterHours = availabilityStatus === DATE.AVAILABILITY_STATUS.AFTER;
      const isOpen = availabilityStatus === DATE.AVAILABILITY_STATUS.OPEN;

      if (isOpen) {
        getLastOptionsGroup().options.push({
          label: time.format('LT'),
          value: time.format(GMI_TIME_FORMAT),
          disabled: isSameDayBPH() || isUnavailable,
        });
      } else if (isAfterHours) {
        const headerLabel = utils.i18n(
          selectsStart ? 'bw_timepicker_after_hours_pickup_separator' : 'bw_timepicker_after_hours_return_separator'
        );
        const extraClass = 'time-picker__item--after-hours';

        if (!getLastOptionsGroup().name || getLastOptionsGroup().name !== headerLabel) {
          optionsGroups.push({ name: headerLabel, extraClass, tooltip: afterHoursProps, options: [] });
        }

        getLastOptionsGroup().options.push({
          label: time.format('LT'),
          value: time.format(GMI_TIME_FORMAT),
          disabled: isSameDayBPH() || isUnavailable,
          extraClass,
        });
      } else {
        const headerLabel = utils.i18n('bw_time_selector_closed_hours_label');
        const extraClass = 'time-picker__item--closed-hours';

        if (!getLastOptionsGroup().name || getLastOptionsGroup().name !== headerLabel) {
          optionsGroups.push({ name: headerLabel, extraClass, options: [] });
        }

        getLastOptionsGroup().options.push({
          label: time.format('LT'),
          value: time.format(GMI_TIME_FORMAT),
          disabled: true,
          extraClass,
        });
      }
    });
  };

  /**
   * Returns JSX for the tooltip shown when a user clicks on "After Hours" tooltip icon.
   */
  getAfterHoursProps = (selectsStart) =>
    selectsStart ? (
      <div>
        <h3 className='after-hours__title after-hours__title-pick-up-icon'>
          {utils.i18n('bw_timepicker_after_hours_pickup_tooltip_title')}
        </h3>
        <p>{utils.i18n('picking_up_vehicle_easy_alamo')}</p>{' '}
        <p>{utils.i18n('after_hours_additional_steps_confirmation_email')}</p>
      </div>
    ) : (
      <div>
        <h3 className='after-hours__title after-hours__title-pick-up-icon'>
          {utils.i18n('bw_timepicker_after_hours_return_tooltip_title')}
        </h3>
        <p>{utils.i18n('returning_vehicle_location_closes_easy_alamo')}</p>{' '}
        <p>{utils.i18n('tell_park_it_drop_keys_in_dropbox')}</p>
      </div>
    );

  /**
   * getTimeOptions function used to generate available time slots.
   * @param {*} date - The date for which the time slots are being generated.
   * @param {*} eligibility - Location eligibility data (open hours, closed hours, after-hours).
   * @returns
   */
  getTimeOptions = (date, eligibility) => {
    const { startDate, endDate, pickupTime, selectsStart, locationTimezone } = this.props;
    const currentTime = utils.gmi.getDateTimeObjFromTs(date || [], '').startOf('day');
    const endOfDay = utils.gmi.getDateTimeObjFromTs(date || [], '').endOf('day');
    const isSameDay = !!endDate && endDate === startDate;
    const timeType = selectsStart ? 'PICKUP' : 'RETURN';

    const optionsGroups = [{ options: [] }];

    const afterHoursProps = this.getAfterHoursProps(selectsStart);

    const hoursList = this.getHours(currentTime, endOfDay, 30);

    // Processes and categorizes the available time slots.
    this.generateTimeOptions(
      hoursList,
      eligibility,
      timeType,
      locationTimezone,
      () => isSameDayBeforePickupHours(isSameDay, pickupTime, currentTime),
      afterHoursProps,
      optionsGroups,
      selectsStart
    );

    return optionsGroups;
  };

  isStartDateClosedOnDayDatePicker = (day, eligibility) => {
    if (eligibility) {
      const dayInHashFormat = utils.gmi.getDateTimeObjFromTs(day).format('YYYY-MM-DD');
      // Checking if PickUpDate is Closed? from 'AFTER' hours because pickup is possible 24/7
      // This will check is PickUpDate Closed? from 'STANDARD' hours If 'AFTER' is unavailable
      if (!eligibility[dayInHashFormat]?.AFTER) {
        return eligibility[dayInHashFormat]?.STANDARD?.closed;
      }
      return eligibility[dayInHashFormat]?.AFTER?.closed;
    }
    return false;
  };

  isReturnDateClosedOnDayDatePicker = (day, eligibility) => {
    if (eligibility) {
      const dayInHashFormat = utils.gmi.getDateTimeObjFromTs(day).format('YYYY-MM-DD');
      // Checking if ReturnDate is Closed? from 'DROP' hours because return is possible 24/7
      // This will check is ReturnDate Closed? from 'STANDARD' hours If 'DROP' is unavailable
      if (!eligibility[dayInHashFormat]?.DROP) {
        return eligibility[dayInHashFormat]?.STANDARD?.closed;
      }
      return eligibility[dayInHashFormat]?.DROP?.closed;
    }
    return false;
  };

  getSuggestedTimeOptionIndex = (optionsGroups) =>
    optionsGroups
      .map((item) => item.options)
      .flat()
      .findIndex((item) => item.label === SUGGESTED_TIME);

  render() {
    const {
      dateId,
      dateName,
      dateLabel,
      startDate,
      endDate,
      selectsStart,
      selectsEnd,
      helpText,
      timeName,
      timeLabel,
      guided,
      eligibility,
      hoursForStore,
      one_way_rental,
      showErrorsOnLoad,
      isSixColumnGrid,
      monthsShownDatePicker,
      timeAndDatePickerFullHeight,
      contractRestrictions,
      isUnavailableLocationContent,
      time,
      latestDate,
    } = this.props;

    const { minDate, maxDate, lastUpdated } = this.state;
    const furthestDate = (latestDate && new Date(latestDate)) || maxDate;
    const start = startDate ? utils.gmi.getDateTimeObjFromTs(startDate, 'YYYY-MM-DD').toDate() : null;
    const end = endDate ? utils.gmi.getDateTimeObjFromTs(endDate, 'YYYY-MM-DD').toDate() : null;
    let selectedDay = null;
    const isPickUpDate = dateName === 'pickupDate';
    const timeOptions = utils.gmi.isObjectEmpty(hoursForStore) ? eligibility : hoursForStore;
    const currentDate = isPickUpDate ? start : end;
    let startDateClosed = this.isStartDateClosedOnDayDatePicker(start, timeOptions);
    let returnDateClosed = this.isReturnDateClosedOnDayDatePicker(end, timeOptions);
    if (!isPickUpDate) {
      //  if we are on the return date branch selection,
      //  we want to show the pick up date regardless if it is closed or not
      startDateClosed = false;
    }

    if (one_way_rental && isPickUpDate) {
      // same as above but showing return date
      returnDateClosed = false;
    }

    if (selectsStart && !startDateClosed) {
      selectedDay = start;
    } else if (!selectsStart && !returnDateClosed) {
      selectedDay = end;
    }
    let optionsGroups = this.getTimeOptions(currentDate, timeOptions);
    //  if the start or end is closed we want to pass in an empty object
    //  otherwise times will all be closed when they are selecting a new date in
    //  unavailableLocationModal
    if (isPickUpDate && startDateClosed) {
      optionsGroups = this.getTimeOptions(currentDate, {});
    } else if (!isPickUpDate && returnDateClosed) {
      optionsGroups = this.getTimeOptions(currentDate, {});
    }
    const placesFromSuggestion = 6; // 6 places before 12pm should be 9am
    const suggestedOptionIndex = Math.max(this.getSuggestedTimeOptionIndex(optionsGroups) - placesFromSuggestion, 0); // suggested index should be 6 places before 12pm or 0

    const monthsShown = monthsShownDatePicker || isSixColumnGrid ? 1 : 2; // show only 1 month for 6 column BW, or if default value is provided use `monthsShownDatePicker`
    const rightAlignDatepicker = isSixColumnGrid && !isPickUpDate;

    return (
      <div className='fieldset fieldset-group fieldset--date-time'>
        <DatePicker
          name={dateName}
          startDate={startDateClosed ? null : start}
          endDate={returnDateClosed ? null : end}
          onChange={this.handleDateChange}
          selected={selectedDay}
          minDate={minDate}
          maxDate={furthestDate}
          helpText={helpText}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          id={dateId}
          label={dateLabel}
          guided={guided}
          eligibility={timeOptions}
          showErrorsOnLoad={showErrorsOnLoad}
          monthsShown={monthsShown}
          rightAlignDatepicker={rightAlignDatepicker}
          asterisk
          timeAndDatePickerFullHeight={timeAndDatePickerFullHeight}
          isUnavailableLocationContent={isUnavailableLocationContent}
          contractRestrictions={contractRestrictions}
          isRental={Boolean(latestDate)}
        />

        <ResponsiveListbox
          id={timeName}
          name={timeName}
          onChange={this.handleTimeChange}
          className='time-picker'
          label={utils.i18n('common_time')}
          fullLabel={timeLabel}
          mobileLabel={utils.i18n('bw_departure_time_prompt_mobile')}
          fill
          optionsGroups={optionsGroups}
          guided={guided}
          lastUpdated={lastUpdated}
          suggestedOptionIndex={suggestedOptionIndex}
          suggestedOption={SUGGESTED_TIME}
          showErrorsOnLoad={showErrorsOnLoad}
          asterisk
          useMobileVersionForTablet
          time={time}
          timeAndDatePickerFullHeight={timeAndDatePickerFullHeight}
        />
      </div>
    );
  }
}

DateTimeFieldset.propTypes = {
  dateId: PropTypes.string.isRequired,
  dateLabel: PropTypes.string.isRequired,
  dateName: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  selectsStart: PropTypes.bool,
  startDate: PropTypes.string,
  timeId: PropTypes.string.isRequired,
  timeLabel: PropTypes.string.isRequired,
  timeName: PropTypes.string.isRequired,
  eligibility: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  dateError: PropTypes.bool,
  timeError: PropTypes.bool,
  hoursForStore: PropTypes.any,
  isSixColumnGrid: PropTypes.bool,
  monthsShownDatePicker: PropTypes.number,
  timeAndDatePickerFullHeight: PropTypes.bool,
  contractRestrictions: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  locationTimezone: PropTypes.string,
  latestDate: PropTypes.string,
  isUnavailableLocationContent: PropTypes.bool,
};

export default DateTimeFieldset;
