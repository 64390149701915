import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { getNonLoyaltyProfile } from 'actions/profile';
import { isFetchingEndpointSelector } from 'selectors/fetchingSelectors';
import { getReservationToken } from 'selectors/reservationSelectors';
import {
  hasGmaErrorMessagesSelector,
  gmaErrorMessagesSelector,
  isStateAndProvinceEndpointErrorSelector,
} from 'selectors/errorSelectors';
import Form from 'components/Form/Form';
import FreeSpouseBanner from 'components/modals/AdditionalDriverModals/FreeSpouseBanner';
import DriverProfileForm from 'components/ReservationFlow/InResAcceleratedCheckin/DriverProfileForm';
import { addAdditionalDriverByName } from 'actions/reservation/addAdditionalDriverByName';
import { removeAdditionalDriver } from 'actions/reservation/removeAdditionalDriver';
import { addAdditionalDriverDetails } from 'actions/reservation/addAdditionalDriverDetails';
import { closeModal } from 'actions/modal/closeModal';

const domainCountryCode = utils.config.getDomainCountry();
/**
 * AdditionalDriverDetailsForm Component
 * Form for editing additional driver details profile
 *
 * @param {object} props - React Props
 * @param {func} props.renderFooter - function used to render Modal footer, takes the onSubmit handler as first param
 * @param {obj} props.driver - additional driver reservation object
 * @param {func} props.onSubmitSuccess - handler to run after successful form submit
 * @param {func} props.onSubmitFail - handler to run after failed submit
 *
 * @return {JSX} AdditionalDriverDetailsForm jsx component
 */
const AdditionalDriverDetailsForm = ({
  renderFooter,
  driver,
  onSubmitSuccess,
  onSubmitFail,
  hideBanner,
  isSpouseLocalState,
  setIsSpouseLocalState,
  modalKey,
}) => {
  const addAdditionalDriverDetailsAction = useActions(addAdditionalDriverDetails);
  const removeAdditionalDriverAction = useActions(removeAdditionalDriver);
  const getProfileDetails = useActions(getNonLoyaltyProfile);
  const addDriverByName = useActions(addAdditionalDriverByName);
  // action to close the modal
  const [handleCloseModal] = useActions([closeModal]);

  const hasGmaErrorMessages = useSelector(hasGmaErrorMessagesSelector);
  const gmaErrorMessages = useSelector(gmaErrorMessagesSelector);
  const isAddingDriver = useSelector((state) =>
    isFetchingEndpointSelector(state, { endpoint: 'reservations/addAdditionalDriverByName' })
  );
  const [gmaErrorMessage, setGmaErrorMessage] = useState([]);
  const [addDriverByNameErrorState, setAddDriverByNameErrorState] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(domainCountryCode);

  const hasStateAndProvinceServiceError = useSelector((state) =>
    isStateAndProvinceEndpointErrorSelector(state, selectedCountry)
  );

  const reservationToken = useSelector(getReservationToken);

  useEffect(() => {
    if (driver?.individual_id) {
      getProfileDetails(driver.individual_id);
    }
  }, [driver?.individual_id]);

  useEffect(() => {
    // Not calling addDriverByName endpoint when we have an error with stateAndProvince service,
    // as this leads to error, updating additional driver is not allowed.
    if (
      hasGmaErrorMessages &&
      !isAddingDriver &&
      !addDriverByNameErrorState &&
      driver &&
      (hasStateAndProvinceServiceError.length <= 0 || !hasStateAndProvinceServiceError)
    ) {
      utils.dom.loadingOverlay(true);
      setGmaErrorMessage(gmaErrorMessages);
      addDriverByName({
        firstName: driver?.first_name,
        lastName: driver?.last_name,
      }).then((response) => {
        if (response?.messages) {
          setAddDriverByNameErrorState(true);
        }
        utils.dom.loadingOverlay(false);
      });
    }
  }, [hasGmaErrorMessages]);

  // TODO - ideally getProfileDetails would get profile data to be used in initialValues, but the call is failing due to
  //  a GBO_TOKEN_INVALID_CLAIM error
  const initialValues = useMemo(
    () => ({
      license_info: {
        ...driver,
        country_code: domainCountryCode,
      },
      contact_info: {},
      address_info: {
        country_code: domainCountryCode,
      },
    }),
    [driver]
  );

  const onSubmit = (formData) => {
    utils.dom.loadingOverlay(true);
    setAddDriverByNameErrorState(false);
    const onSubmitHandler = () => {
      const newFormData = { ...formData, reservation_token: reservationToken };

      const phoneData = utils.profile.contactInfoPayloadParser(formData);
      newFormData.contact_info.phone_set = phoneData.phones;

      addAdditionalDriverDetailsAction(newFormData)
        .then((resp) => {
          resp.messages ? onSubmitFail?.() : onSubmitSuccess?.();
          utils.dom.loadingOverlay(false);
        })
        .catch(() => utils.dom.loadingOverlay(false));
    };

    // In case it's a new driver
    if (!driver) {
      onSubmitHandler();
      return;
    }
    // otherwise remove and add driver
    removeAdditionalDriverAction(driver)
      .then((resp) => (!resp.messages ? onSubmitHandler() : utils.dom.loadingOverlay(false)))
      .catch(() => utils.dom.loadingOverlay(false));
  };

  const handleSelectedCountry = (countryCode) => {
    setSelectedCountry(countryCode);
  };

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <>
          {!hideBanner && (
            <FreeSpouseBanner
              handleCloseModal={handleCloseModal}
              isSpouseLocalState={isSpouseLocalState}
              setIsSpouseLocalState={setIsSpouseLocalState}
              modalKey={modalKey}
            />
          )}
          {(!isSpouseLocalState || hideBanner) && (
            <React.Fragment>
              <p>{utils.i18n('additional_driver_details_modal_copy')}</p>
              <DriverProfileForm
                errorMessages={gmaErrorMessage}
                includeNameFields
                submitHandler={onSubmit}
                initialValues={initialValues}
                renderFooter={renderFooter}
                handleSelectedCountry={handleSelectedCountry}
              />
            </React.Fragment>
          )}
        </>
      )}
    </Form>
  );
};

AdditionalDriverDetailsForm.propTypes = {
  renderFooter: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  modalKey: PropTypes.string,
};

export default AdditionalDriverDetailsForm;
