import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import ANALYTICS from 'constants/analytics';
import { actions, utils as globalUtils } from '@ehi/global-marketing-interface';
import useActions from 'hooks/useActions';
import MODAL, { MODAL_THEMES, MODAL_BUTTON_TYPES } from 'constants/modals';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { i18n } from 'utils/i18n';
import utils from 'utils';
import { capitalizeAll, capitalize } from 'utils/string';
import { skipAnalyticsForLocationTypeFilter } from 'actions/locationFinder';
import { Form } from 'react-final-form';
import Checkbox from 'components/Form/Checkbox';
import THEMES from 'constants/themes';
import { setFiltersByValue } from 'actions/filter/setFiltersByValue';

const { ACCEPT, DECLINE } = MODAL_BUTTON_TYPES;

/**
 * LocationTypeFilterModal
 *
 * @returns {JSX}
 */

const LocationTypeFilterModal = ({ onChange, storeKey, filterConfig, currentFilters }) => {
  const locationFilters =
    globalUtils.isArrayNotEmpty(currentFilters) && currentFilters.length > 1
      ? currentFilters.slice(0, 1)
      : currentFilters;
  const clearFiltersUi = useActions((key) => actions.setFiltersUiByKey(key, []));
  const skipAnalyticsLocationFinder = useActions((bool) => skipAnalyticsForLocationTypeFilter(bool));
  const setFiltersByValueAction = useActions(setFiltersByValue);
  const [locationStateFilters, setLocationStateFilters] = useState(currentFilters);
  const [clearAllState, setClearAllState] = useState(false);

  useEffect(() => {
    setLocationStateFilters(currentFilters);
  }, [locationStateFilters]);

  const handleFilterChange = (params) => {
    setFiltersByValueAction({ storeKey, currentFilters, ...params });
    utils.safeExecute(onChange, currentFilters, params.value);
  };

  // handle modal close and analytics on close modal
  const handleModalClose = (handleClose) => {
    setClearAllState(false);
    handleClose({ skipAnalytics: true });
  };

  const handleCheckboxChange =
    ({ keyPath, isRadio, isSingleSelect, value }) =>
    (e, input, checked, label) => {
      let data = locationFilters;
      const availableItems = locationFilters[0]?.available;
      if (globalUtils.isArrayNotEmpty(availableItems) && globalUtils.isArrayNotEmpty(locationFilters)) {
        const filtered = availableItems.map((item) => {
          const currentValue =
            item.value === value ? { keyPath, isRadio, isSingleSelect, value, checked } : { checked: item.checked };
          return {
            ...item,
            ...currentValue,
          };
        });
        let filteredValues = data[0].values || [];
        filteredValues = filteredValues.filter((val) => val !== value || checked);
        data[0].available = [...filtered];
        if (checked) {
          const values = [...filteredValues, value];
          data[0].values = [...new Set(values)];
        } else {
          data[0].values = [...new Set(filteredValues)];
        }

        data = [...data];
      }
      setLocationStateFilters([...data]);
      clearAllState && setClearAllState(false);
      utils.analytics.interaction(
        ANALYTICS.FILTER,
        ANALYTICS.LOCATION,
        checked ? `Added ${label}` : `Removed ${label}`
      );
    };

  const handleApplySearchFilter = (handleClose) => () => {
    skipAnalyticsLocationFinder(true);
    if (clearAllState) {
      clearFiltersUi(storeKey);
    } else {
      const availableItems = locationFilters[0]?.available;
      availableItems.forEach((item) => {
        handleFilterChange({
          keyPath: locationFilters[0].keyPath,
          isRadio: item.isRadio,
          isSingleSelect: item.isSingleSelect,
          checked: item.checked,
        });
      });
    }
    handleModalClose(handleClose);
  };

  const handleClear = () => {
    let data = locationFilters;
    const availableItems = locationFilters[0]?.available;
    if (globalUtils.isArrayNotEmpty(availableItems) && globalUtils.isArrayNotEmpty(locationFilters)) {
      const filtered = availableItems.map((item) => ({
        ...item,
        checked: false,
      }));
      data[0].available = [...filtered];
      data[0].values = [];
      data = [...data];
    }

    setLocationStateFilters([...data]);
    setClearAllState(true);
  };

  const onSubmit = () => {};

  const handleFilter = () => {
    const locationAvailabilityFilters = locationFilters;
    return (
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form className='filterby-location-type-modal__form' onSubmit={handleSubmit}>
            {locationAvailabilityFilters.map((filter) => {
              const { available, category, keyPath } = filter;

              const availableSorted =
                category !== 'Vehicle Class' ? utils.gmi.sortArrayOfObjectsByKey(available, 'label') : available;

              const config = filterConfig.find((item) => item.keyPath === keyPath);
              if (config) {
                const { isRadio, labelHandler } = filterConfig;
                return (
                  <fieldset key={keyPath} className='filterby-location-type-modal'>
                    <div className='modal-themed__filters-group'>
                      {availableSorted.map(({ checked, label, value }) => {
                        const id = `${keyPath}_${value}`;
                        return (
                          <div key={id} className='modal-themed__filters-group-item'>
                            <Checkbox
                              checked={checked}
                              id={id}
                              key={id}
                              label={labelHandler?.(label, value) || label || value}
                              name={keyPath}
                              onChange={handleCheckboxChange({ keyPath, isRadio, value })}
                              theme={THEMES.LIGHT}
                              derivedState
                            />
                          </div>
                        );
                      })}
                    </div>
                  </fieldset>
                );
              }
              return null;
            })}
          </form>
        )}
      </Form>
    );
  };

  const islocationAvailability = globalUtils.isArrayNotEmpty(locationFilters)
    ? locationFilters.filter((item) => item.keyPath !== 'location_availability')
    : [];
  const NoLocationFilterOptions =
    globalUtils.isArrayNotEmpty(locationFilters) && locationFilters.length > 1
      ? true
      : islocationAvailability?.length > 0;
  return (
    <Modal
      modalKey={MODAL.LOCATION_TYPE_FILTER_MODAL}
      header={utils.i18n('location_type_filter_modal_title')}
      clearFilter={utils.i18n('clear_all_filter_title')}
      theme={MODAL_THEMES.WHITE}
      customClass={'location-type__modal'}
      headerRowWithClearButton={true}
      customActionOnClose={() => {
        setClearAllState(false);
        utils.analytics.interaction(
          capitalize(ANALYTICS.MODAL),
          utils.i18n('location_type_filter_modal_title'),
          capitalize(ANALYTICS.CLOSE)
        );
      }}
      handleClearAll={handleClear}
    >
      {({ handleClose }) => (
        <>
          <div className='modal-themed__filters'>
            <div className='modal-themed__horizontal-separator' />
            <p className='modal-themed__paragraph'>{utils.i18n('location_type_filter_modal_description')}</p>
          </div>
          {NoLocationFilterOptions && handleFilter()}
          <div className='column-reverse-footer'>
            <ModalFooter
              buttons={[
                {
                  type: DECLINE,
                  label: i18n('common_cancel'),
                  handler: () => handleModalClose(handleClose),
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      capitalizeAll(ANALYTICS.MODAL),
                      utils.i18n('location_type_filter_modal_title'),
                      capitalizeAll(ANALYTICS.CANCEL)
                    ),
                },
                {
                  type: ACCEPT,
                  handler: handleApplySearchFilter(handleClose),
                  label: i18n('apply_search'),
                  analyticsHandler: () =>
                    utils.analytics.interaction(
                      capitalizeAll(ANALYTICS.MODAL),
                      utils.i18n('location_type_filter_modal_title'),
                      capitalizeAll(ANALYTICS.APPLY_FILTER)
                    ),
                },
              ]}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default LocationTypeFilterModal;

LocationTypeFilterModal.propTypes = {
  onChange: PropTypes.func,
};
