import { connect } from 'react-redux';
import utils from 'utils';
import { GMA_RESPONSE_CODES, MODAL } from 'constants';
import { setPrerateAdditionalInformation, setPrerateAdditionalInformationUnset } from 'actions/bookingWidget';
import { initiateReservationFlow } from 'actions/reservation/initiateReservationFlow';
import { modalQueueSelector } from 'selectors';
import PrerateAdditionalInfoModalContent from './PrerateAdditionalInfoModalContent';

const mapStateToProps = (state) => {
  let contractRequirements = utils.safeToJS(
    state.getIn(['gmi', 'session', 'gma', 'initiate_request', 'contract_details', 'additional_information']),
    []
  );

  const isPreRateAdditionalInfoModalAlreadyOpen =
    utils.safeToJS(modalQueueSelector(state))?.modalKey === MODAL.PRE_RATE_ADDITIONAL_INFO_MODAL;

  //  if validate_additional_info is set to false, it is a post rate item which we address in the review step
  contractRequirements = contractRequirements.filter((requirement) => requirement.validate_additional_info);

  const reservationsError = state.getIn(['gmi', 'messages', 'gma_services', 'reservations/initiate']) || null;

  const hasAdditionalFieldsError =
    (reservationsError &&
      reservationsError.find(
        (message) => message.code === GMA_RESPONSE_CODES.CROS_RES_PRE_RATE_ADDITIONAL_FIELD_REQUIRED
      )) ||
    false;

  return {
    hasAdditionalFieldsError,
    contractRequirements,
    reservationsError,
    isPreRateAdditionalInfoModalAlreadyOpen,
  };
};

const mapDispatchToProps = {
  handleInitiate: initiateReservationFlow,
  setPrerateAdditionalInformation,
  setPrerateAdditionalInformationUnset,
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(PrerateAdditionalInfoModalContent));
