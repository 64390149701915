import React, { Component } from 'react';
import cn from 'classnames';
import utils from 'utils';
import { SESSION_STORAGE } from 'constants/session';
import ToolTip from 'components/ToolTip';
import FieldControl from 'components/Form/FieldControl';
import Button from 'components/Button';
import { propTypes } from './ResponsiveListboxPropTypes';

class ResponsiveListbox extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    const flatOptions =
      (utils.gmi.isArrayNotEmpty(props.optionsGroups) && this._flattenOptionsGroups()) ||
      (utils.gmi.isArrayNotEmpty(props.options) && props.options) ||
      [];

    this.state = {
      uuid: props.id,
      isExpanded: props.expanded,
      selectedId: null, // see: aria-activedescendant
      selectedLabel: null,
      suggestedOption: props.suggestedOption || null,
      highlightedIndex: -1,
      flatOptions,
      characterSearchKey: '', // single alphanumeric key
      characterSearchIndex: 0,
      interactive: false,
      followCursor: false,
    };

    // refs
    this._toggler = React.createRef();
    this._dropdown = React.createRef();

    this._list = React.createRef();
    this.highlightedOptionRef = null; // see: setHighlightedOptionRef
  }

  componentDidMount() {
    !this.props.countrySelector && this.selectOptionByValue(this.props.value);
    window.addEventListener('scroll', this.handleScrollPage);
  }

  handleScrollPage = () => {
    if (this.state.followCursor || this.state.interactive) {
      this.setState({ followCursor: false, interactive: false });
    }
  };

  //  We can't use deep compare here due to the option groups containing a react comp tooltip, this throws an
  //  error.  Instead we compare the lengths.
  compareLengthsOfOptionGroups = (currentOptionGroups, prevOptionGroups) => {
    if (currentOptionGroups.length === prevOptionGroups.length) {
      return false;
    }
    for (let i = 0; i < currentOptionGroups.length; i += 1) {
      const currentOptionsLength = currentOptionGroups[i].options.length;
      const prevOptionsLength = prevOptionGroups[i].options.length;
      if (currentOptionsLength !== prevOptionsLength) {
        return true;
      }
    }
    return false;
  };

  //  TODO: move this out of listbox, we need this due to the reference changing from getting hours
  lengthComparsionOfPropsIfUnavailableLocationModalIsOpen = (currentOptionGroupsProps, previousOptionGroupsProps) => {
    const { isInvalidDateModalOpen } = this.props;
    if (isInvalidDateModalOpen) {
      return this.compareLengthsOfOptionGroups(currentOptionGroupsProps, previousOptionGroupsProps);
    }
    return currentOptionGroupsProps !== previousOptionGroupsProps;
  };

  componentDidUpdate(prevProps) {
    const { lastUpdated, options, optionsGroups, value, time, driversAge, isDriverAgeUpdated } = this.props;

    // If default value changed update list to select the options with this new default value
    // comparing time and value if it is not umatched calling the setOptionByalue  function to update the time value
    if (
      value !== prevProps.value ||
      (lastUpdated && lastUpdated !== prevProps.lastUpdated) ||
      (time && value.length <= 0 && time !== value && time !== prevProps.time)
    ) {
      let selectedOption = value;
      if (time && value.length <= 0 && time !== value && (time !== prevProps.time || time !== value)) {
        selectedOption = time;
      }

      setTimeout(() => {
        this.selectOptionByValue(selectedOption);
      }, 0);
    }

    if (driversAge && isDriverAgeUpdated && isDriverAgeUpdated !== prevProps.isDriverAgeUpdated) {
      setTimeout(() => {
        this.selectOptionByValue(driversAge);
      }, 0);
    }

    //  If InvalidLocationModal is open we do a length comparison due to new hours coming in from the time validation request
    if (this.lengthComparsionOfPropsIfUnavailableLocationModalIsOpen(optionsGroups, prevProps.optionsGroups)) {
      // flatten options groups here and store flat array in state
      utils.gmi.isArrayNotEmpty(optionsGroups) &&
        this.setState({ flatOptions: this._flattenOptionsGroups() }, this.confirmSelectedValueInOptions);
    }

    if (options !== prevProps.options) {
      utils.gmi.isArrayNotEmpty(options) &&
        this.setState({ flatOptions: [...options] }, this.confirmSelectedValueInOptions);
    }
  }

  setHighlightedOptionRef = (element) => {
    this.highlightedOptionRef = element;
    element && this.focusTooltip(element);
    element && this.setState({ selectedId: element.id });
  };

  /**
   * Checks to see if the click triggered by the `handleClickOutside` function is just on the tooltip,
   * in this case we close the tooltip but keep the dropdown open, We are comparing it to a string,
   * because putting a value in a data- attribute changes it to a string
   */
  didClickOnToolTip = (clickPath) =>
    clickPath?.findIndex((nodeInPath) => nodeInPath?.dataset?.isTooltip === 'true') !== -1;

  /**
   * handleClickOutside - Callback utilized by `withClickOutside` enhancer component - set's correct component state when focus is lost
   *
   * @returns {type} Description
   */
  handleClickOutside = (e) => {
    const eventPath = e?.path || (e.composedPath && e?.composedPath());
    //  is expanded AND did the user click on the tooltip on the dropdown
    this.state.isExpanded && !this.didClickOnToolTip(eventPath) && this.hideDropdown();
  };

  /**
   * return true if FocusEvent (focus/blur) relatedTarget is contained in our wrapper
   */
  isRelatedTargetContained = (el) => this.clickOutsideRef.current.contains(el);

  /**
   * Handle Focus on Button or Dropdown List
   */
  handleFocus = (e) => {
    if (!this.isRelatedTargetContained(e.nativeEvent.relatedTarget)) {
      const { onFocus, isFocusedActive } = this.props;
      onFocus && onFocus(e);

      // Force show dropdown if input is focused and is active or have errors.
      // Focus is triggered faster than showGuidedError changes so we have to delay to check it.
      setTimeout(() => {
        const { showGuidedError } = this.props;
        (isFocusedActive || showGuidedError) && this.showDropdown();
      }, 0);
    }
  };

  /**
   * Handle Blur on Button or Dropdown List
   */
  handleBlur = (e) => {
    if (!this.isRelatedTargetContained(e.nativeEvent.relatedTarget)) {
      const { onBlur } = this.props;
      onBlur && onBlur(e);
    }
  };

  /**
   * Handle toggle show/hide options dropdown
   */
  handleToggle = (e) => {
    e.preventDefault();
    const { onToggle } = this.props;

    // If onToggle is set, let callback handle expanding states via prop
    utils.safeExecute(onToggle, this.state);
    this.state.isExpanded ? this.hideDropdown() : this.showDropdown();
  };

  /**
   * Handles selecting currently highlighted option
   */
  handleSelect = (e) => {
    const { breakpoint, useMobileVersionForTablet } = this.props;

    e.preventDefault();
    const {
      dataset: { value },
    } = e.currentTarget;
    this.selectOptionByValue(value);
    sessionStorage.setItem(SESSION_STORAGE.USER_AGE_SELECTED, true);
    this.hideDropdown();

    // blur the input on mobile after selecting a value
    const isMobileMode = breakpoint.isMobile || (breakpoint.isTablet && useMobileVersionForTablet);
    if (isMobileMode) {
      this._list.current.blur();
    }
  };

  /**
   * Handles Mouse Over Highlighting Options
   */
  handleHighlight = (index) => () => this.highlightOption(index);

  /**
   * Scroll handler to help determine whether there is scrolling up or down to show scroll indicators
   * TODO: Prevent Background from Scrolling on Mobile (Lock scrolling)
   */
  handleScroll = () => {
    const { offsetHeight, scrollHeight, scrollTop } = this._list.current;
    const dropdownNodeClassList = this._dropdown.current?.classList;

    if (!this.state.interactive || !this.state.followCursor) {
      this.setState({ followCursor: true, interactive: true });
    }

    if (scrollHeight) {
      scrollTop > 0 ? dropdownNodeClassList?.add('has-scroll-up') : dropdownNodeClassList?.remove('has-scroll-up');
      scrollTop !== scrollHeight - offsetHeight
        ? dropdownNodeClassList?.add('has-scroll-down')
        : dropdownNodeClassList?.remove('has-scroll-down');
    }
  };

  /**
   * Handles the both mouseenter and mouseleave events of the scrolling arrows that appear on the dropdown
   * @dev this is meant for desktop interaction only
   */
  handleMouseEnterLeaveScroll = (e) => {
    const { offsetHeight, scrollHeight, scrollTop } = this._list.current;
    const {
      type,
      currentTarget: {
        dataset: { direction },
      },
    } = e;

    // On mousing over the arrows
    if (type === 'mouseenter') {
      // If mouse over the up arrow, set interval to scroll up
      if (direction === 'up') {
        this.scrollInterval = setInterval(() => {
          if (scrollTop > 0) {
            this._list.current.scrollTop -= 10;
          }
        }, 50);
      }

      // If mouse over down arrow, start scrolling down until it reaches the end
      if (direction === 'down') {
        this.scrollInterval = setInterval(() => {
          if (scrollTop < scrollHeight - offsetHeight) {
            this._list.current.scrollTop += 10;
          }
        }, 50);
      }
    }

    // When mouse leaves the arrow stop scrolling by clearing the interval
    type === 'mouseleave' && this.scrollInterval && clearInterval(this.scrollInterval);
  };

  handleKeyDown = (e) => {
    const key = e.which || e.keyCode;
    const a11yKeys = utils.accessibility.formatKeys(e);
    const isA11yKeys = Object.values(a11yKeys).indexOf(true) !== -1;
    const isAlphanumericKeys = /[a-zA-Z0-9-_ ]/.test(String.fromCharCode(key));
    const role = e.currentTarget.getAttribute('role');

    // If dropdown is closed while focus is on the toggler
    if (!this.state.isExpanded && (isA11yKeys || isAlphanumericKeys)) {
      if (
        !a11yKeys.tab &&
        !a11yKeys.shiftTab &&
        !a11yKeys.shift &&
        !a11yKeys.escape &&
        !a11yKeys.arrowLeft &&
        !a11yKeys.arrowRight
      ) {
        e.preventDefault();
        this.showDropdown();

        switch (true) {
          case a11yKeys.arrowUp:
            this.highlightPrev();
            break;
          case a11yKeys.arrowDown:
            this.highlightNext();
            break;
          case a11yKeys.home:
            this.highlightOption(0);
            break;
          case a11yKeys.end:
            this.highlightOption(this.state.flatOptions.length - 1);
            break;
          default:
            if (isAlphanumericKeys) {
              const matchedIndex = this.findOption(key);
              matchedIndex > -1 && this.highlightOption(matchedIndex, true);
            }
            break;
        }
      }
    }

    // If dropdown expanded while focused
    // TODO: Add tab focus on tooltips if it exists
    if (this.state.isExpanded && role === 'listbox') {
      // If any of these keys are pressed, suppressed default behavior
      (isA11yKeys || isAlphanumericKeys) && e.preventDefault();
      const option = this.state.flatOptions[this.state.highlightedIndex];

      switch (true) {
        case a11yKeys.arrowUp:
          // Highlight and scroll to previous item
          this.highlightPrev();
          break;
        case a11yKeys.arrowDown:
          // Highlight and scroll to next item
          this.highlightNext();
          break;
        case a11yKeys.home:
          // Highlight first item
          this.highlightOption(0, true);
          break;
        case a11yKeys.end:
          // Highlight last item
          this.highlightOption(this.state.flatOptions.length - 1, true);
          break;
        case a11yKeys.escape:
        case a11yKeys.shiftTab:
        case a11yKeys.tab:
          // If escaping while focused on dropdown, refocus on Toggler before closing it
          e.stopPropagation();
          this._toggler.current.focus();
          this.hideDropdown();
          break;
        case a11yKeys.enter:
        case a11yKeys.space:
          if (option && !option.isSeparator) {
            // Focus on togglers
            !this.props.guided && this._toggler.current.focus();
            // Select current highlighted item and close Dropdown
            this.selectOptionByValue(option.value);
            this.hideDropdown();
          }
          // If it has tooltip, open it
          option && option.tooltip && this.openTooltip(this.highlightedOptionRef);
          break;
        default:
          if (isAlphanumericKeys) {
            e.stopPropagation();
            const matchedIndex = this.findOption(key);
            matchedIndex > -1 && this.highlightOption(matchedIndex, true);
          }
          break;
      }
    }
  };

  /**
   * Show Dropdown
   */
  showDropdown = () => {
    const { breakpoint, useMobileVersionForTablet } = this.props;

    this.setState({ isExpanded: true }, () => {
      const { highlightedIndex, suggestedOption } = this.state;
      this._list.current.focus();

      this._list.current.dispatchEvent(new CustomEvent('scroll'));
      highlightedIndex > -1 && this.highlightOption(highlightedIndex, true);

      if (highlightedIndex < 0 && suggestedOption) {
        const suggestedIndex = this._getSelectedIndexFromValueOrLabel(suggestedOption);
        suggestedIndex > -1 && this.highlightOption(suggestedIndex, true);
      }

      const isMobileMode = breakpoint.isMobile || (breakpoint.isTablet && useMobileVersionForTablet);
      if (isMobileMode) {
        utils.scrollLock();
      }
    });
  };

  /**
   * Hide Dropdown
   */
  hideDropdown = () => {
    const { breakpoint, useMobileVersionForTablet } = this.props;
    this.setState({
      isExpanded: false,
      characterSearchKey: '',
      characterSearchIndex: 0,
      highlightedIndex: this._getSelectedIndexFromValueOrLabel(this.props.value),
    });
    const isMobileMode = breakpoint.isMobile || (breakpoint.isTablet && useMobileVersionForTablet);
    if (isMobileMode) {
      utils.scrollLock({ toggleValue: false });
    }
  };

  /**
   * Highlights Option Item by Index
   *
   * @param index {Number}
   */
  highlightOption = (index, shouldScrollToOption = false) => {
    this.setState({ highlightedIndex: index }, () => {
      shouldScrollToOption && this.scrollToOption(this.highlightedOptionRef);
    });
  };

  /**
   * Highlights the previous sibling option of currently highlighted item
   */
  highlightPrev = () => {
    const { flatOptions, highlightedIndex } = this.state;
    let prevIndex = highlightedIndex > 0 ? highlightedIndex - 1 : flatOptions.length - 1;
    let option = flatOptions[prevIndex];

    // Skip disabled options & headers without tooltips
    while (option.disabled || (option.isSeparator && !option.tooltip)) {
      prevIndex = prevIndex > 0 ? prevIndex - 1 : flatOptions.length - 1;
      option = flatOptions[prevIndex];
    }
    this.highlightOption(prevIndex, true);
  };

  /**
   * Highlights the next sibling option of currently highlighted item
   */
  highlightNext = () => {
    const { flatOptions, highlightedIndex } = this.state;
    let nextIndex = highlightedIndex < flatOptions.length - 1 ? highlightedIndex + 1 : 0;
    let option = flatOptions[nextIndex];

    // Skip disabled options & headers without tooltips
    while (option.disabled || (option.isSeparator && !option.tooltip)) {
      nextIndex = nextIndex < flatOptions.length - 1 ? nextIndex + 1 : 0;
      option = flatOptions[nextIndex];
    }

    this.highlightOption(nextIndex, true);
  };

  /**
   * Focus on Tooltips button
   */
  focusTooltip = (node) => {
    const tooltip = node.querySelector('button');
    tooltip && tooltip.focus();
  };

  /**
   * Opens tooltip in separator
   */
  openTooltip = (node) => {
    const tooltip = node.querySelector('button');
    tooltip.click();
  };

  /**
   * Selects an option by value
   *
   * @param value {any}
   */
  selectOptionByValue = (value) => {
    utils.safeExecute(this.props.onChange, value);
    this._setSelectedLabelFromValue(value);
    const valueIndex = this._getSelectedIndexFromValueOrLabel(value);
    valueIndex > -1 && this.highlightOption(valueIndex);
  };

  // when the options change, confirm that the selected value is among them and not disabled
  // if it is not, default to the option with "selected: true" or '' (empty string)
  confirmSelectedValueInOptions = () => {
    const { flatOptions } = this.state;
    const stringValue = this.props.value?.toString() || this.props.value;
    const selectedOption =
      flatOptions.find(({ disabled, value }) => !disabled && value?.toString() === stringValue)?.value?.toString() ||
      flatOptions.find((option) => option.selected)?.value?.toString() ||
      '';

    this.selectOptionByValue(selectedOption);
  };

  /**
   * Finds first option that matches key entered text
   *
   * @param key {Number} the keycode
   * @returns Node | undefined {Object}
   */
  findOption = (key) => {
    const character = String.fromCharCode(key);
    const { characterSearchKey, characterSearchIndex, flatOptions } = this.state;
    let newIndex = 0;

    if (characterSearchKey === character) {
      // user re-entered the same key, increment the index to search further in the list
      newIndex = characterSearchIndex + 1;
    }

    const foundIndex = flatOptions
      .slice(newIndex)
      .findIndex(
        (option) => !option.isSeparator && !option.disabled && RegExp(character).test(option.label[0].toUpperCase())
      );

    newIndex = foundIndex > -1 ? foundIndex + newIndex : -1;

    this.setState({
      characterSearchKey: character,
      characterSearchIndex: newIndex,
    });

    return newIndex;
  };

  /**
   * Scrolls scrollable dropdowns to the option by index
   *
   * @param index {Number}
   */
  scrollToOption = (element) => {
    this._list.current.scrollTop = element.offsetTop;
    this._list.current.dispatchEvent(new CustomEvent('scroll'));
  };

  /**
   * @private
   * @returns {Number} Returns index of option from value
   */
  _getSelectedIndexFromValueOrLabel = (value) =>
    this.state.flatOptions.findIndex((option) => option.value?.toString() === value.toString());

  _setSelectedLabelFromValue = (value) => {
    const stringValue = value?.toString() || value;
    const selectedOption = this.state.flatOptions.find((option) => option.value?.toString() === stringValue);
    this.setState({ selectedLabel: selectedOption ? selectedOption.label : value });
  };

  _renderSeparator = (option, index, isHighlighted) => {
    const { uuid, isExpanded } = this.state;
    const { name, extraClass, tooltip } = option;

    return (
      <li
        key={`${name}_${index}`} // group names aren't unique, "Closed" or "After Hours" may appear multiple times
        id={`${uuid}_${name}_${index}`}
        className={cn(`responsive-listbox__options-group-header ${extraClass}`)}
        role='separator'
        aria-label={name}
        data-group-name={name}
        ref={isHighlighted ? this.setHighlightedOptionRef : null}
      >
        <span className='label'>{name}</span>
        {tooltip && isExpanded && (
          <ToolTip interactive={this.state.followCursor} followCursor={this.state.interactive} placement='left-end'>
            {tooltip}
          </ToolTip>
        )}
      </li>
    );
  };

  /**
   * @private
   */
  _renderOption = (option, index, isHighlighted) => {
    const { disabled, label, name, value, extraClass } = option;
    return (
      <li
        key={value}
        id={`${this.state.uuid}_${value}`}
        className={cn(extraClass, { disabled, highlight: isHighlighted && !disabled })}
        data-value={value}
        data-name={name}
        role='option'
        aria-selected={!disabled && isHighlighted ? 'true' : 'false'}
        aria-disabled={disabled}
        onMouseEnter={!disabled ? this.handleHighlight(index) : null}
        onClick={!disabled ? this.handleSelect : null}
        ref={isHighlighted ? this.setHighlightedOptionRef : null}
      >
        {label}
      </li>
    );
  };

  /**
   * @private
   */
  _flattenOptionsGroups = () => {
    const { optionsGroups } = this.props;
    const flatOptions = [];

    optionsGroups.forEach(({ name, options, ...group }) => {
      flatOptions.push({ name, isSeparator: true, ...group });
      flatOptions.push(...options.map((option) => ({ ...option, name })));
    });
    return flatOptions;
  };

  handleResponsiveListBox = () => {
    const {
      label,
      fullLabel,
      mobileLabel,
      required,
      name,
      showGuidedError,
      renderGuidedError,
      timeAndDatePickerFullHeight,
      asterisk,
    } = this.props;

    const { uuid, selectedId, selectedLabel, isExpanded, flatOptions, highlightedIndex } = this.state;
    const asteriskValue = asterisk || required ? 'required' : selectedLabel;
    const ariaLabelSelectedRequiredValue = selectedLabel?.length > 0 ? selectedLabel : asteriskValue;
    const ariaLabel = `${fullLabel || label} ${ariaLabelSelectedRequiredValue}`;
    return (
      <React.Fragment>
        <Button
          plain
          buttonRef={this._toggler}
          aria-haspopup='listbox'
          aria-label={ariaLabel}
          aria-expanded={isExpanded ? 'true' : 'false'}
          id={`${uuid}__toggler`}
          name={name}
          className='responsive-listbox__toggler dropdown'
          onMouseDown={this.handleToggle}
          onKeyDown={this.handleKeyDown}
          type='button'
        >
          {selectedLabel}
        </Button>

        <div
          ref={this._dropdown}
          className={cn('responsive-listbox__dropdown', {
            hidden: isExpanded,
            'responsive-listbox__dropdown--full-height': timeAndDatePickerFullHeight,
          })}
        >
          <div className='responsive-listbox__mobile-header'>
            {showGuidedError && renderGuidedError()}
            {!showGuidedError && <span className='responsive-listbox__mobile-label'>{mobileLabel || label}</span>}
            <Button
              plain
              tabIndex='-1'
              type='button'
              className='responsive-listbox__close'
              aria-label={utils.i18n('location_search_close_cta')}
              onClick={this.hideDropdown}
            >
              {utils.i18n('location_search_close_cta')}
            </Button>
          </div>

          {showGuidedError && renderGuidedError()}

          <span
            className='scroll-up'
            data-direction='up'
            aria-hidden='true'
            onMouseEnter={this.handleMouseEnterLeaveScroll}
            onMouseLeave={this.handleMouseEnterLeaveScroll}
          />
          <ul
            ref={this._list}
            data-is-time-selector='true'
            role='listbox'
            tabIndex='-1'
            aria-labelledby={`${uuid}__toggler`}
            aria-activedescendant={selectedId}
            className='responsive-listbox__list'
            onScroll={this.handleScroll}
            onKeyDown={this.handleKeyDown}
          >
            {utils.gmi.isArrayNotEmpty(flatOptions) &&
              flatOptions.map((option, index) =>
                option.isSeparator
                  ? this._renderSeparator(option, index, highlightedIndex === index)
                  : this._renderOption(option, index, highlightedIndex === index)
              )}
          </ul>
          <span
            className='scroll-down'
            data-direction='down'
            aria-hidden='true'
            onMouseEnter={this.handleMouseEnterLeaveScroll}
            onMouseLeave={this.handleMouseEnterLeaveScroll}
          />
        </div>
      </React.Fragment>
    );
  };

  handleCustomCombobox = () => {
    const { currentPageTitle, type } = this.props;

    const { uuid, selectedId, isExpanded, flatOptions, highlightedIndex } = this.state;
    // const ariaLabel = `${countrySelector}`;

    return (
      <React.Fragment>
        <div
          className={cn('country-selector__select', {
            'country-selector__expanded-outline': isExpanded,
          })}
          ref={this._toggler}
          aria-haspopup='listbox'
          aria-controls={`${uuid}__toggler`}
          aria-expanded={isExpanded ? 'true' : 'false'}
          aria-autocomplete='list'
          aria-labelledby={`${uuid}__input-label`}
          id={`${uuid}__input`}
          role={type}
          onMouseDown={this.handleToggle}
          onKeyDown={this.handleKeyDown}
          tabIndex={0}
        >
          <label id={`${uuid}__input-label`} className='country-selector__label'>
            {utils.i18n('country')}:
            <span id='selected_country' className='country-selector__value'>
              {currentPageTitle}
            </span>
          </label>
        </div>

        <ul
          ref={this._list}
          role='listbox'
          tabIndex='-1'
          id={`${uuid}__toggler`}
          aria-activedescendant={selectedId}
          onScroll={this.handleScroll}
          onKeyDown={this.handleKeyDown}
        >
          {utils.gmi.isArrayNotEmpty(flatOptions) &&
            flatOptions.map((option, index) =>
              option.isSeparator
                ? this._renderSeparator(option, index, highlightedIndex === index)
                : this._renderOption(option, index, highlightedIndex === index)
            )}
        </ul>
      </React.Fragment>
    );
  };

  render() {
    const { disabled, required, breakpoint, countrySelector } = this.props;

    const { isExpanded } = this.state;

    return (
      <div
        ref={this.clickOutsideRef}
        className={cn({
          'responsive-listbox': !countrySelector,
          'country-selector__container': countrySelector,
          disabled,
          required,
          expanded: !disabled && isExpanded,
          'force-mobile': !countrySelector && breakpoint.isMobile,
        })}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {countrySelector ? this.handleCustomCombobox() : this.handleResponsiveListBox()}
      </div>
    );
  }
}

const Listbox = utils.withClickOutside(ResponsiveListbox);

const ListboxField = (props) =>
  !props.countrySelector ? (
    <FieldControl id={utils.dom.uuid()} {...props} dropdown type='listbox'>
      <Listbox />
    </FieldControl>
  ) : (
    <Listbox id={utils.dom.uuid()} {...props} type='combobox' />
  );

export default ListboxField;
