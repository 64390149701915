import { connect } from 'react-redux';
import utils from 'utils';
import { actions } from '@ehi/global-marketing-interface';
import { MODAL } from 'constants';
import { setSelectedCurrencyToggleData } from 'actions/vehicleClass';
import { breakpointSelector } from 'selectors/breakpoint';
import { getDefaultLoyaltyContractProfileStatus } from 'selectors/profileSelectors';
import {
  selectedVehicleSelector,
  preSelectedVehicleSelector,
  filteredResultsSelector,
  allResultsSelector,
  guaranteedVehiclesAvailableSelector,
  vehiclesHaveCouponSelector,
  selectAppliedVehicleClassFiltersFromSession,
  getDestinationCurrencyCode,
} from 'selectors/vehicleSelectSelectors';
import { getCurrencyToggleDetails } from 'selectors/currencyToggleSelectors';

import {
  getModifyFlag,
  contractDetailsSelector,
  isEuropeanUnionCountry,
  isLastMinuteSpecialsReservationSelector,
  isPlanAheadSpecialsReservationSelector,
  getShouldShowDriveTypeFilter,
} from 'selectors/reservationSelectors';
import { getSortFilterValue, filteredFilterItemsSelector } from 'selectors/uiStateSelectors';
import { reservationCouponsSelector, hasCarClassesSelector, getAllVehicles } from 'selectors/reservationFlowSelectors';
import { openModal } from 'actions/modal/openModal';
import { openModalWithProps } from 'actions/modal/openModalWithProps';
import VehicleSelect from './VehicleSelect';

const storeKey = 'car_classes';

const mapStateToProps = (state) => {
  const gmiReservationState = state.getIn(['gmi', 'session', 'gbo', 'reservation']);
  const { hasFilters, numFiltersSelected } = filteredResultsSelector(state, storeKey);
  const { prepayExists, lowestCarFilterPrices } = allResultsSelector(state, storeKey);
  const currencyToggleDetails = getCurrencyToggleDetails(state, storeKey);

  return {
    breakpoint: breakpointSelector(state),
    reservationDataAvailable: !!gmiReservationState,
    contractDetails: contractDetailsSelector(state),
    vehicles: getSortFilterValue(state, storeKey),
    prepayExists,
    lowestCarFilterPrices,
    hasFilters,
    selectedVehicle: selectedVehicleSelector(state),
    preSelectedVehicle: preSelectedVehicleSelector(state),
    numFiltersSelected,
    isTrueModify: getModifyFlag(state),
    prepaySelected: gmiReservationState?.get('prepay_selected'),
    coupons: reservationCouponsSelector(state),
    vehiclesHaveCoupon: vehiclesHaveCouponSelector(state),
    guaranteedVehiclesAvailable: guaranteedVehiclesAvailableSelector(state),
    hasCarClasses: hasCarClassesSelector(state),
    isEuropeanUnionCountry: isEuropeanUnionCountry(state),
    isLastMinuteSpecials: isLastMinuteSpecialsReservationSelector(state),
    isPlanAheadSpecials: isPlanAheadSpecialsReservationSelector(state),
    allVehicles: getAllVehicles(state),
    storeKey,
    vehicleSearchFilters: selectAppliedVehicleClassFiltersFromSession(state),
    isPayTypeSelected: currencyToggleDetails?.isPayTypeSelected,
    destinationCurrencyCode: getDestinationCurrencyCode(state),
    currencyToggleData: currencyToggleDetails,
    filteredItems: filteredFilterItemsSelector(state, storeKey),
    showDriveTypeFilter: getShouldShowDriveTypeFilter(state),
    hasDefaultLoyaltyContractProfile: getDefaultLoyaltyContractProfileStatus(state),
  };
};

const mapDispatchToProps = {
  initVehicleSelectFilters: actions.reservations.initVehicleSelectFilters,
  clearFiltersUi: () => actions.setFiltersUiByKey(storeKey, []),
  handlePrepayTermsModal: (guaranteedRes) =>
    openModalWithProps(MODAL.LEARN_ABOUT_PREPAY_AND_PAY_LATER, { guaranteedRes }),
  openNoSpecialsAvailableModal: (isLMS) =>
    openModal(isLMS ? MODAL.NO_LMS_AVAILABLE_MODAL : MODAL.NO_PAS_AVAILABLE_MODAL),
  setSelectedCurrencyToggleData,
  showCurrencyConversionModal: () => openModal(MODAL.CURRENCY_CONVERSION_DETAILS_MODAL),
};

export default connect(mapStateToProps, mapDispatchToProps)(utils.toJS(VehicleSelect));
