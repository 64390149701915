import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getModelJson } from 'utils/aem';
import { getCurrentPagePath } from 'utils/url';
import { trimSpaceFromStringValueOfObject } from 'utils/object';
import { i18n } from 'utils/i18n';
import CUSTOM_PATH from 'constants/customPathCheckIn';
import RESERVATION_LOOKUP from 'constants/reservationLookup';
import useActions from 'hooks/useActions';
import { reservationLookup } from 'actions/reservationLookup';
import { profileDataSelector } from 'selectors/profileSelectors';
import { preSelectedReservationRetrieveDataSelector } from 'selectors/reservationSelectors';
import ServiceErrors from 'components/ServiceErrors';
import PreSelectedMessages from 'components/PreSelectedMessages';
import ReservationLookupForm from 'components/ReservationLookupForm';
import CancelledReservationModal from 'components/modals/CancelledReservationModal';
import STCIneligibleModal from 'components/modals/STCIneligibleModal';
import STCAlreadyActivatedModal from 'components/modals/STCAlreadyActivatedModal';
import CheckInIneligibleModal from 'components/modals/CheckInIneligibleModal';
import SignInModal from 'components/modals/SignInModal';
import CheckInLoyaltyConflictModal from 'components/modals/CheckInLoyaltyConflictModal';

const { CUSTOM_PATH_ORIGIN } = CUSTOM_PATH;
const { LOOK_UP_TYPE } = RESERVATION_LOOKUP;

/**
 * Custom Path Check-In Lookup Check-in Lookup Component
 *
 * This component uses AEM ( model.json) to get data for the input labels.
 *
 * @param {string} firstNameLabel - if author added a custom label in AEM it will return the label for the first name input field.
 * @param {string} lastNameLabel - if author added a custom label in AEM it will return the label for the last name input field.
 * @param {string} confirmationNumberLabel - if author added a custom label in AEM it will return the label for the confirmation number input field.
 *
 * @return {JSX}
 */
const CustomPathCheckInLookup = ({ firstNameLabel, lastNameLabel, confirmationNumberLabel }) => {
  const profile = useSelector((state) => profileDataSelector(state, { first_name: true, last_name: true }));
  const preSelectedReservation = useSelector(preSelectedReservationRetrieveDataSelector);
  const reservationLookupHandler = useActions(reservationLookup);
  const [data, setData] = useState({});
  const [brandInformation, setBrandInformation] = useState({});

  // Get model.json data directly from AEM
  useEffect(() => {
    getModelJson(setData);
  }, []);

  /**
   * Get brand information from the model.json object and store it in state.
   * customPathCheckInLandingPage is the only value not being retrieve from the model.json,
   * we need to get the current page relative by using utils.url.getCurrentPagePath().
   * */
  useEffect(() => {
    if (typeof data === 'object' && data !== null) {
      const {
        customPathBrandCode,
        customPathBrandName,
        customPathLogoImageUrl,
        faqCaasTag,
        customerSupportAssistanceCaasTag,
      } = data;
      setBrandInformation({
        customPathCheckInBrandName: customPathBrandName,
        customPathCheckInBrandCode: customPathBrandCode,
        customPathCheckInCheckInLogo: customPathLogoImageUrl,
        customPathCheckInLandingPage: getCurrentPagePath(),
        customPathCheckInFaqCaasTag: faqCaasTag,
        customPathCheckInCustomerSupportCaasTag: customerSupportAssistanceCaasTag,
      });
    }
  }, [data]);

  const formSubmitHandler = ({ reservationData }) => {
    const trimStringValuesObj = trimSpaceFromStringValueOfObject(reservationData);
    // merge reservation details and partner brand information to send to GMA in order for the data
    // to be store in GMA session for future use in FE
    const mergedObjects = { ...trimStringValuesObj, ...brandInformation };
    reservationLookupHandler({
      reservationData: mergedObjects,
      origin: CUSTOM_PATH_ORIGIN.LOOK_UP,
      lookupType: LOOK_UP_TYPE.CUSTOM_PATH_LOOK_UP,
    });
  };

  return (
    <section className='post-res-lookup theme--light-blue'>
      <span className='post-res-lookup__legend' aria-hidden={true}>
        {i18n('review_required_indication')}
      </span>
      <ServiceErrors />
      <PreSelectedMessages />
      <ReservationLookupForm
        className='post-res-lookup__form'
        onFormSubmit={formSubmitHandler}
        submitButtonText={i18n('common_start')}
        loyaltyProfile={profile}
        submitDTMTrack='check_in|look_up|start'
        preSelectedReservation={preSelectedReservation}
        labels={{ firstNameLabel, lastNameLabel, confirmationNumberLabel }}
      />
      <STCIneligibleModal />
      <STCAlreadyActivatedModal />
      <CancelledReservationModal />
      <CheckInIneligibleModal />
      <SignInModal />
      <CheckInLoyaltyConflictModal />
    </section>
  );
};

CustomPathCheckInLookup.propTypes = {
  // This comes from AEM's `MapTo`...
  firstNameLabel: PropTypes.string,
  lastNameLabel: PropTypes.string,
  confirmationNumberLabel: PropTypes.string,
};

export default CustomPathCheckInLookup;
