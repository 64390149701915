import React, { useState, useEffect } from 'react';
import utils from 'utils';
import useActions from 'hooks/useActions';
import { fetchCurrentSession } from 'actions/session';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { MODAL, MODAL_THEMES } from 'constants';
import { closeModal } from 'actions/modal/closeModal';
import { timeoutRedirect, getSessionTimeoutChannel } from 'utils/redirect';
import { showSessionTimeoutModal, getTabInstanceId } from 'actions/modal/showSessionTimeoutModal';
import { SESSION_TIMEOUT_MODAL_CHANNEL_STATES } from 'constants/modals';
import SessionTimeoutModalContent from './SessionTimeoutModalContent';

const { SESSION_TIMEOUT, CONTINUE_WORKING } = SESSION_TIMEOUT_MODAL_CHANNEL_STATES;

// Module-level variables to track listener registration
let isListenerRegistered = false;
let lastSessionTimeoutTime = 0;
let lastContinueWorkingTime = 0;
const COOLDOWN_PERIOD = 500;

// Create a singleton handler for broadcast messages
const createBroadcastHandlers = () => {
  const handlers = {
    dispatch: null,

    setDispatch: (dispatch) => {
      handlers.dispatch = dispatch;
    },

    getSessionHandler: (showTimeoutModal) => () => {
      const currentTime = Date.now();
      if (currentTime - lastSessionTimeoutTime < COOLDOWN_PERIOD) {
        return;
      }

      lastSessionTimeoutTime = currentTime;
      if (showTimeoutModal) {
        showTimeoutModal();
      }
    },

    getContinueWorkingHandler: (handleContinueWorking) => () => {
      const currentTime = Date.now();
      if (currentTime - lastContinueWorkingTime < COOLDOWN_PERIOD) {
        return;
      }
      lastContinueWorkingTime = currentTime;

      if (handleContinueWorking) {
        handleContinueWorking(true);
      }
    },
  };

  return handlers;
};

// Create singleton handlers
const broadcastHandlers = createBroadcastHandlers();

/**
 * TimeOutModal open the timeout modal when the user is inactive on the site for the 20minutes
 * @param {object} props - Properties passed into the react component",
 */
const SessionTimeoutModal = ({ sessionTimeoutValue }) => {
  const updateCurrentSession = useActions(fetchCurrentSession);
  const showTimeoutModal = useActions(() => showSessionTimeoutModal(true));
  const sessionTimeoutChannel = getSessionTimeoutChannel();
  const TAB_INSTANCE_ID = getTabInstanceId();
  const closeModalAction = useActions(closeModal);

  const [documentTitleState] = useState(document.title);
  const TAB_ID_SESSION = utils.localStorage.getTabIdSession();

  const handleContinueWorking = (fromTimeOutChannel) => {
    updateCurrentSession();
    timeoutRedirect.start(() => showTimeoutModal());
    sessionStorage.setItem(`pauseTimer_${TAB_ID_SESSION}`, true);
    document.title = documentTitleState;

    if (fromTimeOutChannel) {
      closeModalAction(MODAL.SESSION_TIMEOUT_MODAL);
    } else {
      // Notify other tabs
      sessionTimeoutChannel.postMessage({
        type: CONTINUE_WORKING,
        senderId: TAB_INSTANCE_ID,
      });
    }
  };

  // Set up broadcast channel listener (only once)
  useEffect(() => {
    // Only register the listener if it hasn't been registered yet
    if (!isListenerRegistered) {
      isListenerRegistered = true;

      const handleMessage = (event) => {
        // Ignore messages from this same tab
        if (event.data.senderId === TAB_INSTANCE_ID) {
          return;
        }

        if (event.data.type === SESSION_TIMEOUT) {
          broadcastHandlers.getSessionHandler(showTimeoutModal)(event);
        } else if (event.data.type === CONTINUE_WORKING) {
          broadcastHandlers.getContinueWorkingHandler(handleContinueWorking)();
        }
      };

      sessionTimeoutChannel.addEventListener('message', handleMessage);
    }
  }, []);

  return (
    <Modal
      modalKey={MODAL.SESSION_TIMEOUT_MODAL}
      header={utils.i18n('session_timeout_message')}
      customActionOnClose={handleContinueWorking}
      theme={MODAL_THEMES.WHITE}
    >
      {({ handleClose }) => (
        <div id={`${MODAL.SESSION_TIMEOUT_MODAL}_desc`}>
          <SessionTimeoutModalContent
            sessionTimeoutValue={sessionTimeoutValue}
            handleClose={handleClose}
            documentTitleState={documentTitleState}
            tabInstanceId={TAB_INSTANCE_ID}
          />
        </div>
      )}
    </Modal>
  );
};

export default SessionTimeoutModal;

SessionTimeoutModal.propTypes = {
  runTimer: PropTypes.bool,
  countDown: PropTypes.number,
  modalKey: PropTypes.bool,
};
