import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';

const canUseDOM = Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);

const TooltipTrigger = ({
  children,
  // Some defaults changed in the hook implementation.
  // For backward compatibility we have to override them here.
  closeOnReferenceHidden = true,
  defaultTooltipShown,
  getTriggerRef,
  modifiers,
  placement = 'auto',
  portalContainer = canUseDOM ? document.body : null,
  tooltip,
  tooltipShown,
  usePortal = canUseDOM,
  interactive = false,
  followCursor = false,
  ...restProps
}) => {
  const { triggerRef, getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible, state } = usePopperTooltip(
    {
      // Some props renamed in the hook implementation.
      defaultVisible: defaultTooltipShown,
      visible: tooltipShown,
      closeOnTriggerHidden: closeOnReferenceHidden,
      interactive,
      followCursor,
      ...restProps,
    },
    {
      placement,
      modifiers,
    }
  );

  const reference = children({
    // No longer required, for backward compatibility.
    getTriggerProps: (props) => props,
    triggerRef: setTriggerRef,
  });

  const popper = tooltip({
    tooltipRef: setTooltipRef,
    getArrowProps,
    getTooltipProps,
    placement: state ? state.placement : null,
  });

  useEffect(() => {
    if (typeof getTriggerRef === 'function') getTriggerRef(triggerRef);
  }, [triggerRef, getTriggerRef]);

  const renderTooltip = (portal) => (portal ? createPortal(popper, portalContainer) : popper);

  return (
    <>
      {reference}
      {visible ? renderTooltip(usePortal) : null}
    </>
  );
};

export default TooltipTrigger;
