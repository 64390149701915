import React, { Component } from 'react';
import utils from 'utils';
import FieldControl from 'components/Form/FieldControl';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CapsLockControl from '../CapsLockControl';

/**
 * PasswordField Description:
 * an input field with type "password" and a button to toggle type "text"
 *
 * @param {object} props - React Props
 * @param {string} props.name: unique field name
 * @param {string} props.id - unique field id
 * @param {string} props.label - a descriptive label for the user to read
 * @param {boolean} props.full - will make the field fill the width of its container
 * @param {boolean} props.error - Show Error State
 * @param {boolean} props.attention - Show Attention State
 * @param {boolean} props.checkCapsLock - Show Caps Lock State
 * @param {Array | boolean} props.validations
 * @param {object} props.breakpoint - Breakpoint selector data
 * @return {JSX} ...
 */
class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };

    this.uuid = utils.dom.uuid();
  }

  toggleShowPassword = () => {
    // The analytics function reads the button's innerText when clicked, but the text updates too quickly,
    // causing incorrect values. To fix this, setState is wrapped in setTimeout,
    // ensuring analytics captures the correct button text.
    setTimeout(() => {
      this.setState(({ showPassword }) => ({ showPassword: !showPassword }));
    }, 0);
  };

  renderField = (props) => {
    const { disabled } = props;
    const { showPassword } = this.state;

    return (
      <FieldControl
        {...props}
        id={this.props.id || this.uuid}
        type={!disabled && showPassword ? 'text' : 'password'}
        className={cn('field-control--password', this.props.className)}
      >
        <input />
        <Button
          link
          className='field-control--show-password-btn'
          onClick={this.toggleShowPassword}
          type='button'
          disabled={disabled}
        >
          {!disabled && utils.i18n(showPassword ? 'field_password_hide' : 'field_password_show')}
        </Button>
      </FieldControl>
    );
  };

  render() {
    const { checkCapsLock, breakpoint, ...props } = this.props;
    const { isDesktop } = breakpoint;

    return isDesktop && checkCapsLock ? (
      <CapsLockControl>
        {(capsLockControlProps) => this.renderField({ ...props, ...capsLockControlProps })}
      </CapsLockControl>
    ) : (
      this.renderField(props)
    );
  }
}

PasswordField.defaultProps = {
  checkCapsLock: true,
};

PasswordField.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  full: PropTypes.bool,
  error: PropTypes.bool,
  attention: PropTypes.bool,
  validations: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  breakpoint: PropTypes.object,
};

export default PasswordField;
