import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import { utils as globalUtils } from '@ehi/global-marketing-interface';
import {
  Location,
  DatesTimes,
  Vehicle,
  Extras,
  Mileage,
  AdditionalInformation,
  RenterInfoSection,
  RenterInfoExpandCTA,
} from 'components/RentalDetails';
import { capitalize } from 'utils/string';
import { isDomainCodeLanguageCodeEqual } from 'utils/locations';
import { ANALYTICS } from 'constants';
import COUNTRY_CODE from 'constants/countryCode';
import HelpfulTipsPickup from 'components/HelpfulTipsPickup';
import AboutPayLater from 'components/AboutPayLater';
import AboutPrepay from 'components/AboutPrepay';
import AboutYourPayment from 'components/AboutYourPayment';
import ModifyCancelReservation from 'components/ModifyCancelReservation';
import { getArrayValuesAsString } from 'utils/vehicles';

const ReservationDetails = ({
  oneWayRental,
  pickupLocation,
  returnLocation,
  pickupTime,
  returnTime,
  fullName,
  maskedEmail,
  vehicleName,
  makeModelOrSimilarText,
  transmission,
  peopleCapacity,
  luggageCapacity,
  twoOrFourDoorText,
  carImage,
  isDriveHappyDeal,
  flightInfo,
  setIndexOfPolicyComponentViaPolicyCode,
  priceSummary,
  showViewDetailsModal,
  extras,
  showLearnMoreModal,
  mileageInfo,
  prepaySelected,
  afterHoursPickup,
  afterHoursReturn,
  showAHViewDetailsModal,
  additionalInformation,
  isCancelled,
  contractDetails,
  isVehicleLimitedInventory,
  maskedPhoneNumber,
  billingAccount,
  selectedBundleWithExtras,
  extrasWithoutBundles,
  partner_reward_program,
  numberOfExtrasSelected,
  breakpoint,
  isThirdPartyReservation,
  memberNumber,
  guaranteedResCardRequired,
  selectedPaymentMethod,
  coupons,
  isFoundRental,
  policies,
  openTermsAndConditionsModal,
  isTourVoucherReservation,
  voucherExcludedValue,
  openFlightDelayModal,
  displayFlightDelayLink,
  isEligible,
  selectedPaymentType,
  features,
  isCurrentTrip,
  modifyNotAllowedReasons,
  isTourReservation,
  showModifyModal,
  modifyCancelResExternalLink,
  thirdPartyReservationSupportNumber,
  showCancelModal,
  canCancelReservation,
  paymentMethod,
  appliedCancelFeeDetails,
  vehicleCode,
  vehicleSearchFilters,
}) => {
  const { estimated_total_payment, estimated_total_view, estimated_total_vehicle_view, payment_line_items } =
    priceSummary || {};
  const savings = payment_line_items?.SAVINGS || [];
  const chargedRate = payment_line_items?.VEHICLE_RATE || [];

  let expandButtonKey = 'confirmation_reservation_details_expand_button';

  const freeUpgradeApplied = utils.isFreeUpgradeAvailable(coupons);
  const showTotalPriceAdjustments = !!savings?.length || freeUpgradeApplied;

  const hasAdditionalInfo = additionalInformation?.some((info) => info.value);

  const isFrDomainWithFrLanguage = isDomainCodeLanguageCodeEqual(COUNTRY_CODE.FR, COUNTRY_CODE.FR.toLowerCase());

  useEffect(() => {
    // Analytics for sending interactions if the car selected one is preferred vehicle class
    if (
      vehicleSearchFilters?.selectedVehicleCodes &&
      globalUtils.isArrayNotEmpty(vehicleSearchFilters?.selectedVehicleCodes) &&
      vehicleCode &&
      vehicleSearchFilters.selectedVehicleCodes.includes(vehicleCode)
    ) {
      const { selectedValues } = vehicleSearchFilters;
      let vehicleClassNames = selectedValues.sort();
      vehicleClassNames = getArrayValuesAsString(selectedValues);
      utils.analytics.interaction(ANALYTICS.CHECKBOX, capitalize(ANALYTICS.SELECTED), vehicleClassNames);
    }
  }, []);

  const getTotalCopy = (type) => {
    const isMobile = type === 'mobile';

    if (isTourVoucherReservation) {
      return isMobile ? 'amount_due_at_pickup_value' : 'amount_due_at_pickup';
    }
    if (!isTourVoucherReservation && prepaySelected) {
      return isMobile
        ? 'confirmation_reservation_details_pre_pay_total'
        : 'confirmation_reservation_details_pre_pay_total_desktop';
    }

    return isMobile
      ? 'confirmation_reservation_details_pay_later_total'
      : 'confirmation_reservation_details_pay_later_total_desktop';
  };

  if (breakpoint.isMobile) {
    expandButtonKey = getTotalCopy('mobile');
  }

  const handleFlightModal = () => {
    utils.analytics.interaction(
      ANALYTICS.MODAL,
      isEligible ? 'flight modification eligible' : 'flight delayed faq ineligible',
      ANALYTICS.OPEN
    );
    openFlightDelayModal();
  };

  const modifyCancelReservationComponent = () => (
    <ModifyCancelReservation
      isThirdPartyReservation={isThirdPartyReservation}
      modifyNotAllowedReasons={modifyNotAllowedReasons}
      isTourReservation={isTourReservation}
      displayFlightDelayLink={displayFlightDelayLink}
      showModifyModal={showModifyModal}
      modifyCancelResExternalLink={modifyCancelResExternalLink}
      thirdPartyReservationSupportNumber={thirdPartyReservationSupportNumber}
      openFlightDelayModal={openFlightDelayModal}
      isEligible={isEligible}
      showCancelModal={showCancelModal}
      canCancelReservation={canCancelReservation}
    />
  );

  return (
    <section className='confirmation__reservation-details'>
      {!isCancelled && !isCurrentTrip && !breakpoint.isDesktop && modifyCancelReservationComponent()}

      <div className='row'>
        <h2 className='confirmation__reservation-details__header'>{utils.i18n('confirmation_reservation_details')}</h2>

        <div className='confirmation__reservation-details__section-row'>
          <div className='confirmation__reservation-details__content component-theme--light'>
            <div className='confirmation__reservation-details__content__details-row location-date-time-row'>
              {oneWayRental ? (
                <>
                  <div className='confirmation__reservation-details__content__location-date-time-column'>
                    <Location oneWayRental={oneWayRental} pickupLocation={pickupLocation} />
                    <DatesTimes
                      pickupTime={pickupTime}
                      labels={false}
                      afterHoursPickup={afterHoursPickup}
                      showAHViewDetailsModal={showAHViewDetailsModal}
                      dateTimeInLowerCase={isFrDomainWithFrLanguage}
                    />
                  </div>

                  <div className='confirmation__reservation-details__content__location-date-time-column'>
                    <Location oneWayRental={oneWayRental} returnLocation={returnLocation} />
                    <DatesTimes
                      returnTime={returnTime}
                      labels={false}
                      afterHoursReturn={afterHoursReturn}
                      showAHViewDetailsModal={showAHViewDetailsModal}
                      dateTimeInLowerCase={isFrDomainWithFrLanguage}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Location
                    oneWayRental={oneWayRental}
                    pickupLocation={pickupLocation}
                    returnLocation={returnLocation}
                  />
                  <DatesTimes
                    pickupTime={pickupTime}
                    returnTime={returnTime}
                    afterHoursPickup={afterHoursPickup}
                    afterHoursReturn={afterHoursReturn}
                    showAHViewDetailsModal={showAHViewDetailsModal}
                    dateTimeInLowerCase={isFrDomainWithFrLanguage}
                  />
                </>
              )}
            </div>

            <RenterInfoSection
              fullName={fullName}
              memberNumber={memberNumber}
              maskedEmail={maskedEmail}
              maskedPhoneNumber={maskedPhoneNumber}
              isFoundRental={isFoundRental}
              isCancelled={isCancelled}
              flightInfo={flightInfo}
              handleFlightModal={handleFlightModal}
              partner_reward_program={partner_reward_program}
              displayFlightDelayLink={displayFlightDelayLink}
              contractDetails={contractDetails}
            />
            {hasAdditionalInfo && (
              <div className='confirmation__reservation-details__content__details-row vehicle-row'>
                <AdditionalInformation additionalInformation={additionalInformation} />
              </div>
            )}

            {coupons[0] && (
              <div className='confirmation__reservation-details__content__details-row vehicle-row'>
                <p className='rental-details__section-label rental-details__section-label--coupon'>
                  <span>{utils.i18n('coupon_confirmation_section_label')}</span>
                </p>
                <p className='rental-details__section-row'>{coupons[0].description}</p>
              </div>
            )}

            <div className='confirmation__reservation-details__content__details-row vehicle-row'>
              <Vehicle
                name={vehicleName}
                makeModelOrSimilarText={makeModelOrSimilarText}
                transmission={transmission}
                peopleCapacity={peopleCapacity}
                luggageCapacity={luggageCapacity}
                twoOrFourDoorText={twoOrFourDoorText}
                carImage={carImage}
                isDriveHappyDeal={isDriveHappyDeal}
                isVehicleLimitedInventory={isVehicleLimitedInventory}
                isGuaranteedRes={guaranteedResCardRequired}
                freeUpgradeApplied={freeUpgradeApplied}
                features={features}
              />

              {mileageInfo && <Mileage mileageInfo={mileageInfo} />}
            </div>

            {!!extras.length && (
              <div className='confirmation__reservation-details__content__details-row vehicle-row'>
                <Extras
                  extras={extras}
                  numberOfExtrasSelected={numberOfExtrasSelected}
                  showPrice={false}
                  selectedBundleWithExtras={selectedBundleWithExtras}
                  extrasWithoutBundles={extrasWithoutBundles}
                  displayAsList={true}
                />
              </div>
            )}

            <RenterInfoExpandCTA
              breakpoint={breakpoint}
              expandButtonKey={expandButtonKey}
              isTourVoucherReservation={isTourVoucherReservation}
              voucherExcludedValue={voucherExcludedValue}
              estimated_total_view={estimated_total_view}
              freeUpgradeApplied={freeUpgradeApplied}
              priceSummary={priceSummary}
              estimated_total_payment={estimated_total_payment}
              estimated_total_vehicle_view={estimated_total_vehicle_view}
              savings={savings}
              prepaySelected={prepaySelected}
              showLearnMoreModal={showLearnMoreModal}
              isThirdPartyReservation={isThirdPartyReservation}
              showViewDetailsModal={showViewDetailsModal}
              extrasWithoutBundles={extrasWithoutBundles}
              numberOfExtrasSelected={numberOfExtrasSelected}
              extras={extras}
              selectedBundleWithExtras={selectedBundleWithExtras}
              chargedRate={chargedRate}
              showTotalPriceAdjustments={showTotalPriceAdjustments}
            />
          </div>

          <div className='confirmation__reservation-details__side-bar'>
            {!isCancelled && !isCurrentTrip && breakpoint.isDesktop && modifyCancelReservationComponent()}
            {!isCancelled && (
              <HelpfulTipsPickup
                setIndexOfPolicyComponentViaPolicyCode={setIndexOfPolicyComponentViaPolicyCode}
                prepaySelected={prepaySelected}
                hidePolicies={!policies.length}
                openTermsAndConditionsModal={openTermsAndConditionsModal}
                policies={policies}
              />
            )}
            {prepaySelected && (
              <AboutPrepay isCancelled={isCancelled} appliedCancelFeeDetails={appliedCancelFeeDetails} />
            )}
            {!prepaySelected && !billingAccount && (
              <AboutPayLater
                isCancelled={isCancelled}
                selectedPaymentMethod={selectedPaymentMethod}
                guaranteedResCardRequired={guaranteedResCardRequired}
              />
            )}
            {!prepaySelected && billingAccount && selectedPaymentType && contractDetails && (
              <AboutYourPayment
                contractDetails={contractDetails}
                paymentMethod={paymentMethod}
                billingAccount={billingAccount}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ReservationDetails.propTypes = {
  // These are the unique props, remaining props are passed from Confirmation Container and type checked in Confirmation
  setIndexOfPolicyComponentViaPolicyCode: PropTypes.func,
  fullName: PropTypes.string,
  transmission: PropTypes.string,
  carImage: PropTypes.string,
  flightInfo: PropTypes.string,
  isDriveHappyDeal: PropTypes.bool,
  isVehicleLimitedInventory: PropTypes.bool,
  maskedPhoneNumber: PropTypes.string,
  numberOfExtrasSelected: PropTypes.number,
  breakpoint: PropTypes.object,
  partner_reward_program: PropTypes.shape({
    code: PropTypes.string,
    member_id: PropTypes.string,
    program_name: PropTypes.string,
  }),
  isThirdPartyReservation: PropTypes.bool,
  guaranteedResCardRequired: PropTypes.bool,
  coupons: PropTypes.array.isRequired,
  isFoundRental: PropTypes.bool,
  isTourReservation: PropTypes.bool,
  showModifyModal: PropTypes.func.isRequired,
  thirdPartyReservationSupportNumber: PropTypes.string,
  showCancelModal: PropTypes.func.isRequired,
  contractDetails: PropTypes.object,
};

export default ReservationDetails;
