import React, { Fragment } from 'react';
import utils from 'utils';
import DateTimeFieldset from 'components/Form/DateTimeFieldset';

const LocationDateTimeFieldGroup = ({
  pickupDate,
  returnDate,
  pickupTime,
  returnTime,
  pickupEligibility,
  returnEligibility,
  guided,
  pickup_location_id,
  return_location_id,
  onDateChangeReturn,
  onTimeChangeReturn,
  onDateChangePickUp,
  onTimeChangePickUp,
  showErrorsOnLoadReturn,
  showErrorsOnLoadPickup,
  one_way_rental,
  isSixColumnGrid,
  monthsShownDatePicker,
  timeAndDatePickerFullHeight,
  contractRestrictions,
  isUnavailableLocationContent,
}) => (
  <Fragment>
    <DateTimeFieldset
      selectsStart
      startDate={pickupDate}
      endDate={returnDate}
      dateId='pickupDate'
      dateName='pickupDate'
      dateLabel={utils.i18n('date_range_label_pickup')}
      helpText={utils.i18n('date_range_help_text_pickup')}
      onDateChange={onDateChangePickUp}
      timeId='pickupTime'
      timeName='pickupTime'
      timeLabel='Pickup Time'
      time={pickupTime}
      one_way_rental={one_way_rental}
      eligibility={pickupEligibility}
      locationId={pickup_location_id}
      onTimeChange={onTimeChangePickUp}
      guided={guided}
      showErrorsOnLoad={showErrorsOnLoadPickup}
      isSixColumnGrid={isSixColumnGrid}
      monthsShownDatePicker={monthsShownDatePicker}
      timeAndDatePickerFullHeight={timeAndDatePickerFullHeight}
      contractRestrictions={contractRestrictions}
      isUnavailableLocationContent={isUnavailableLocationContent}
    />

    <DateTimeFieldset
      selectsEnd
      startDate={pickupDate}
      endDate={returnDate}
      dateId='returnDate'
      dateName='returnDate'
      dateLabel={utils.i18n('date_range_label_return')}
      helpText={utils.i18n('date_range_help_text_return')}
      onDateChange={onDateChangeReturn}
      timeId='returnTime'
      timeName='returnTime'
      timeLabel='Return Time'
      time={returnTime}
      one_way_rental={one_way_rental}
      pickupTime={pickupTime}
      eligibility={returnEligibility}
      locationId={return_location_id}
      onTimeChange={onTimeChangeReturn}
      showErrorsOnLoad={showErrorsOnLoadReturn}
      guided={guided}
      isSixColumnGrid={isSixColumnGrid}
      monthsShownDatePicker={monthsShownDatePicker}
      timeAndDatePickerFullHeight={timeAndDatePickerFullHeight}
      contractRestrictions={contractRestrictions}
      isUnavailableLocationContent={isUnavailableLocationContent}
    />
  </Fragment>
);

export default LocationDateTimeFieldGroup;
