import React from 'react';
import PropTypes from 'prop-types';
import utils from 'utils';
import cn from 'classnames';
import Button from 'components/Button';
import Picture from 'components/Picture';
import VehicleDetailsIconList from 'components/VehicleDetailsIconList';
import TimeAndDistance from 'components/RentalDetails/RentalDetailsSharedComponents/TimeAndDistance';
import BundleAddedCost from 'components/RentalDetails/RentalDetailsSharedComponents/BundleAddedCost';
import InPathVehicleUpgradeRentalDetails from 'components/ReservationFlow/InPathVehicleUpgrade/InPathVehicleUpgradeRentalDetails';
import guaranteedResSvg from 'images/guaranteed-res-2C.svg';

const vehiclesTitle = utils.i18n('rental_details_vehicle');

const Vehicle = ({
  name,
  makeModelOrSimilarText,
  transmission,
  peopleCapacity,
  luggageCapacity,
  twoOrFourDoorText,
  features,
  carImage,
  chargedRate,
  estimatedTotalVehicleView,
  estimatedTotalVehiclePayment,
  asterisk,
  isDriveHappyDeal,
  handleEdit,
  isVehicleLimitedInventory,
  selectedBundleWithExtras,
  isGuaranteedRes,
  freeUpgradeApplied,
  hideEditButtons,
  shouldDisplayUpgradeOption = false,
  isSTCFlow = false,
}) => (
  <div className='rental-details__section rental-details__vehicle'>
    <p className='rental-details__section-label'>
      <span>{vehiclesTitle}</span>
      {handleEdit && !hideEditButtons && (
        <Button
          link
          onClick={handleEdit('vehicle')}
          ariaText={utils.i18n('rental_details_edit_aria_label', [vehiclesTitle])}
          data-dtm-track={`rental_details|${utils.i18n('rental_details_vehicle')}|edit`}
        >
          {utils.i18n('rental_details_edit')}
        </Button>
      )}
    </p>

    <div className='rental-details__vehicle-information'>
      <div className='rental-details__vehicle-stats'>
        {isGuaranteedRes && (
          <strong className='rental-details__guaranteed-res-label'>
            <img src={guaranteedResSvg} alt={utils.i18n('guaranteed_res_icon_alt')} />
            {utils.i18n('guaranteed_res_label')}
          </strong>
        )}

        {freeUpgradeApplied && (
          <div className='rental-details__free-upgrade'>
            {utils.i18n('coupon_name_applied', [utils.i18n('coupon_upgrade_adjustment_name')])}
          </div>
        )}

        <p
          className={cn('rental-details__vehicle-name', {
            'rental-details__limited-inventory-icon--vehicle': isVehicleLimitedInventory,
          })}
        >
          {name}
        </p>
        <p className='rental-details__similar-make'>
          {isDriveHappyDeal
            ? makeModelOrSimilarText
            : utils.i18n('vehicle_details_model_or_similar', [makeModelOrSimilarText])}
        </p>

        {features && (
          <VehicleDetailsIconList
            transmission={transmission}
            peopleCapacity={peopleCapacity}
            luggageCapacity={luggageCapacity}
            twoOrFourDoorText={twoOrFourDoorText}
            features={features}
            isDriveHappyDeal={isDriveHappyDeal}
          />
        )}
      </div>
      {carImage && (
        <div className='rental-details__vehicle-image'>
          <Picture src={carImage} alt={makeModelOrSimilarText} srcMobile={carImage} srcTablet={carImage} />
        </div>
      )}
    </div>

    {shouldDisplayUpgradeOption && !isSTCFlow && <InPathVehicleUpgradeRentalDetails />}

    {estimatedTotalVehicleView || chargedRate?.length ? (
      <>
        <TimeAndDistance
          chargedRate={chargedRate}
          estimatedTotalVehicleView={estimatedTotalVehicleView}
          estimatedTotalVehiclePayment={estimatedTotalVehiclePayment}
          asterisk={asterisk}
        />
        {selectedBundleWithExtras && <BundleAddedCost selectedBundleWithExtras={selectedBundleWithExtras} />}
      </>
    ) : null}
  </div>
);

Vehicle.propTypes = {
  name: PropTypes.string,
  makeModelOrSimilarText: PropTypes.string,
  transmission: PropTypes.string,
  peopleCapacity: PropTypes.number,
  luggageCapacity: PropTypes.number,
  twoOrFourDoorText: PropTypes.string,
  carImage: PropTypes.string,
  chargedRate: PropTypes.array,
  estimatedTotalVehicleView: PropTypes.object,
  asterisk: PropTypes.bool,
  handleEdit: PropTypes.func,
  hideEditButtons: PropTypes.bool,
  isDriveHappyDeal: PropTypes.bool,
  isVehicleLimitedInventory: PropTypes.bool,
  selectedBundleWithExtras: PropTypes.object,
  isGuaranteedRes: PropTypes.bool,
  freeUpgradeApplied: PropTypes.bool,
  shouldDisplayUpgradeOption: PropTypes.bool,
  features: PropTypes.array,
};

export default Vehicle;
