import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { sanitize } from 'utils/validation';
import { i18n } from 'utils/i18n';
import print from 'utils/print';
import { dtm, analyticsInteraction } from 'utils/analytics';
import { config } from 'utils/config';
import MODAL, { MODAL_THEMES } from 'constants/modals';
import ANALYTICS from 'constants/analytics';
import { breakpointSelector } from 'selectors/breakpoint';
import { formattedPoliciesSelector } from 'selectors/policiesSelectors';
import { getKeyFactStaticContentSelector } from 'selectors/keyRentalPoliciesSelectors';
import {
  rentalTermsAndConditions,
  isLicensee as isLicenseeSelector,
  licenseeName as licenseeNameSelector,
  legalName as legalNameSelector,
} from 'selectors/reservationSelectors';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { getRentalTermsAndConditions } from 'actions/reservation/getRentalTermsAndConditions';
import PolicyTab from './PolicyTab';

const PoliciesModal = ({ keyRentalFacts, isFetchingRentalTermRef, shouldFetchRental }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [termsByLang, setTermsLanguage] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(0);

  const breakpoint = useSelector(breakpointSelector);
  const policies = useSelector(formattedPoliciesSelector);
  const rentalTermsAndConditionsList = useSelector(rentalTermsAndConditions);
  const isFranchisee = useSelector(isLicenseeSelector);
  const licenseeName = useSelector(licenseeNameSelector);
  const legalName = useSelector(legalNameSelector);
  const keyFactStaticContent = useSelector(getKeyFactStaticContentSelector);

  const getRentalTermsAndConditionsAction = useActions(getRentalTermsAndConditions);

  const { isTabletOrMobile } = breakpoint;
  const shouldExpandMenu = isTabletOrMobile && expanded;
  const shouldDefaultRenderDescription = policies || rentalTermsAndConditionsList;
  const franchiseeName = licenseeName || legalName;

  const handleExpand = (index, title) => {
    setExpandedIndex(index);
    setExpanded(!expanded);
    if (expanded && isTabletOrMobile) {
      analyticsInteraction(ANALYTICS.DRAWER, title.toLowerCase(), ANALYTICS.OPEN);
    }
    if (!isTabletOrMobile) {
      analyticsInteraction(ANALYTICS.DRAWER, title.toLowerCase(), ANALYTICS.OPEN);
    }
  };

  const handleHeaderCTA = () => {
    setExpanded(!expanded);
  };

  const setLanguageContent = (rentalTC) => {
    const locale = config.getLocale();
    let languageSelected = termsByLang[locale];

    // first render to default to terms for domain locale
    if (!languageSelected) {
      rentalTermsAndConditionsList?.forEach((term) => {
        termsByLang[term.locale] = termsByLang[term.locale] || term;
      });
      languageSelected = termsByLang[locale] || rentalTermsAndConditionsList?.[0]; // if not found, we'll default the first in the arry
      setSelectedLanguage(languageSelected);
      setTermsLanguage(termsByLang);
    } else if (rentalTC && rentalTermsAndConditionsList) {
      // if rentalTC is updated
      rentalTermsAndConditionsList?.forEach((term) => {
        termsByLang[term.locale] = termsByLang[term.locale] || term;
      });
      languageSelected = rentalTermsAndConditionsList?.[0] || termsByLang[locale];

      setTermsLanguage(termsByLang);
    } else {
      setSelectedLanguage(languageSelected);
    }
  };

  const resetState = () => {
    setSelectedLanguage(false);
    setTermsLanguage({});
    setExpandedIndex(0);
  };

  useEffect(() => {
    if (rentalTermsAndConditionsList) {
      setLanguageContent(rentalTermsAndConditionsList);
    }
    // condition to wait for the response of the current session call `current` and
    // rentalTermsAndConditions` based on if `gbo.reservation` object is present or not.

    if (!rentalTermsAndConditionsList && shouldFetchRental && !isFetchingRentalTermRef?.current) {
      resetState();
      getRentalTermsAndConditionsAction();
      if (isFetchingRentalTermRef) {
        isFetchingRentalTermRef.current = true;
      }
    }
  }, [rentalTermsAndConditionsList, policies]);

  const handleChange = (e) => {
    const code = e.target.value;
    const languageSelected = termsByLang[code];
    setSelectedLanguage(languageSelected);
  };

  const handlePrint = () => print(['.policies-link-modal']);

  const getByTitle = () => {
    if (!keyRentalFacts) {
      if (rentalTermsAndConditionsList?.length > 0 && expandedIndex === 0) {
        return selectedLanguage?.name;
      }
      return i18n('key_facts_modal_rental_policies_title');
    }
    return i18n('key_facts_modal_title');
  };

  const getFormatedKeyFacts = () => {
    const { staticContent = {} } = keyFactStaticContent;
    const formatedKeyFacts = [];

    Object.keys(staticContent).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(staticContent, key)) {
        const value = staticContent[key];

        // When is simple content
        if (value.content) {
          let content = '';

          const isGBDomain = config.isGBDomain();
          // when we are with rules of the road and in UK domain
          if (key === 'rulesOfTheRoadStaticContent' && isGBDomain) {
            // subtitle
            content += `<h4>${i18n('driving_in_uk_read_traffic_rules')}</h4>`;
            // content with link
            content += `<p class="policies-link-modal__paragraph"><a className="link--external" target="_blank" rel="noopener noreferrer" href="${i18n(
              'key_facts_gb_rules_of_the_road_link'
            )}">${value.content}</a></p>`;
          }

          if (value.subTitle) {
            content += `<h4>${value.subTitle}</h4>`;
          }

          // Custom link wrapper for EU rules of the road
          if (key === 'rulesOfTheRoadStaticContent') {
            content += `<a className="link--external" target="_blank" rel="noopener noreferrer" href="${i18n(
              'key_facts_eu_rules_of_the_road_link'
            )}">${value.content}</a>`;
          } else if (value.content) {
            content += value.content;
          }
          formatedKeyFacts.push({
            name: value.title,
            content,
          });
        }

        // when is content with subsections
        if (value.items) {
          if (value.items?.[0]?.policy_text) {
            let content = '';
            if (value.subTitle) {
              content += `<h4>${value.subTitle}</h4>`;
            }
            value.items.forEach((item) => {
              content += `<p class="policies-link-modal__subtitle">${item.description}:</p>`;

              if (item.policy_text) {
                content += item.policy_text;
              }
            });
            formatedKeyFacts.push({
              name: value.title,
              content,
            });
          }

          // when is content with conditionals subsections
          if (value.items?.[0]?.items) {
            let content = '';
            value.items.forEach((item) => {
              if (item.items.length > 0) {
                content += `<h4>${item.subTitle}</h4>`;
                item.items.forEach((subItem) => {
                  content += `<p class="policies-link-modal__subtitle">${subItem.name}:</p>`;

                  if (subItem.detailed_description) {
                    content += subItem.detailed_description;
                  }
                });
              }
            });
            formatedKeyFacts.push({
              name: value.title,
              content,
            });
          }
        }
      }
    });

    return formatedKeyFacts;
  };

  let combinedPolicesAndTermsConditions = !keyRentalFacts ? policies : getFormatedKeyFacts();
  let selectedContent = policies?.[expandedIndex]?.content;
  if (rentalTermsAndConditionsList?.length > 0) {
    if (!keyRentalFacts) {
      rentalTermsAndConditionsList[0].name = i18n('key_facts_modal_rental_terms_and_conditions_title');
      combinedPolicesAndTermsConditions = [rentalTermsAndConditionsList[0], ...combinedPolicesAndTermsConditions];
    }

    if (expandedIndex === 0 && !keyRentalFacts) {
      selectedContent = expandedIndex === 0 && selectedLanguage?.rental_terms_and_conditions_text;

      if (selectedContent && isFranchisee && franchiseeName) {
        selectedContent = `<p>${i18n('rental_will_be_serviced_by', [franchiseeName])}</p>${selectedContent}`;
      }
    } else {
      selectedContent = combinedPolicesAndTermsConditions?.[expandedIndex]?.content;
    }
  }

  const hideToggleState = rentalTermsAndConditionsList?.length > 0 && expandedIndex !== 0;
  const hideLanguageAndPrintToggle = isTabletOrMobile && shouldExpandMenu;
  return (
    <Modal
      customClass='policies-link-modal'
      modalKey={!keyRentalFacts ? MODAL.RENTAL_POLICIES : MODAL.KEY_RENTAL_FACTS}
      stickyHeader={false}
      header={!keyRentalFacts ? i18n('key_facts_modal_rental_policies_title') : i18n('key_facts_modal_title')}
      headerRowWithClearButton={true}
      showHeaderCTA={isTabletOrMobile && true}
      isExpanded={expanded}
      handleHeaderCTA={handleHeaderCTA}
      headerCtaTitle={!keyRentalFacts ? i18n('view_list_of_all_policies') : i18n('view_list_all_key_rental_facts')}
      theme={MODAL_THEMES.WHITE}
    >
      {() => (
        <div className='policies-link-modal'>
          {isTabletOrMobile && <div className='modal-themed__horizontal-separator' />}
          {!hideLanguageAndPrintToggle && (
            <div
              className={cn('policies-link-modal__container', {
                'print-toggle': hideToggleState || keyRentalFacts,
              })}
            >
              <div
                className={cn('policies-link-modal__language-toggle__container', {
                  'hide-language-toggle': hideToggleState || keyRentalFacts,
                })}
              >
                <select
                  className={cn('policies-link-modal__language-toggle__select')}
                  aria-label={i18n('key_facts_modal_rental_terms_and_conditions_language_toggle')}
                  onChange={handleChange}
                  value='selected_language'
                >
                  <option value={selectedLanguage?.locale}>{selectedLanguage?.locale_label}</option>
                  {rentalTermsAndConditionsList &&
                    rentalTermsAndConditionsList.map(
                      (option) =>
                        option?.locale !== selectedLanguage?.locale && (
                          <option key={option?.locale} value={option?.locale}>
                            {option?.locale_label}
                          </option>
                        )
                    )}
                </select>
                {selectedLanguage?.locale_label && (
                  <span id='selected_language' className='language-toggle__selected'>
                    {selectedLanguage?.locale_label}
                  </span>
                )}
              </div>

              <Button
                link
                data-dtm-track={dtm(ANALYTICS.UI_BUTTON, ANALYTICS.TERMS_AND_CONDITIONS, ANALYTICS.PRINT)}
                className='policies-link-modal__print'
                ariaText={i18n('tab_content_print')}
                onClick={handlePrint}
              >
                <span>{i18n('tab_content_print')}</span>
              </Button>
            </div>
          )}
          <div
            className={cn('policies-link-modal__content', {
              'policies-link-modal__content-has-collapsed': hideLanguageAndPrintToggle,
            })}
          >
            <ul
              role='tablist'
              className={cn('policies-link-tabs', {
                'policies-link-tabs--expanded': shouldExpandMenu,
              })}
            >
              {combinedPolicesAndTermsConditions &&
                combinedPolicesAndTermsConditions.map((policy, index) => (
                  <li key={index} className='policies-link-tabs--item' role='presentation'>
                    <PolicyTab
                      {...policy}
                      customClass='policies-link-tabs'
                      index={index}
                      key={`${policy.name}-title`}
                      title={policy.name}
                      setActiveIndex={handleExpand}
                      expanded={expandedIndex === index}
                    />
                  </li>
                ))}
            </ul>
            {shouldDefaultRenderDescription && (
              <div className={`policies-link-tabs__description`} role='tabpanel'>
                {isTabletOrMobile && <h2 className='policies-link-tabs__description-title'>{getByTitle()}</h2>}
                <div dangerouslySetInnerHTML={sanitize(selectedContent)} />
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PoliciesModal;
