import { MapTo, Container } from '@adobe/aem-react-editable-components';
import spreadModelProps from 'helpers/spreadModelProps';

const ContainerEditableConfig = {
  emptyLabel: 'Container Container',
  isEmpty: (props) => !props[':itemsOrder'] || props[':itemsOrder'].length < 1,
};

// Mapping the Container to resolve :resourceType console error, by statically importing the Container from react editable components
export default MapTo('/apps/aweb/components/content/container')(spreadModelProps(Container), ContainerEditableConfig);
