import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import utils from 'utils';
import cn from 'classnames';
import Anchor from 'components/Anchor';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { WINDOW_OBJECT_KEYS, PROFILE, MODAL } from 'constants';
import { logout } from 'actions/authentication';
import { openModal } from 'actions/modal/openModal';
import { breakpointSelector } from 'selectors';
import ResflowLogoutModal from 'components/modals/ResflowLogoutModal';
import useClickOutside from 'hooks/useClickOutside';

/**
 * AuthenticatedFlyout Description:
 *
 * @param {object} props - React Props
 * @param {boolean} props.isResPath - Flag to differentiate the general and the resflow flyout
 * @param {boolean} props.disableNavigation - Flag to disable My Profile and My Trips links (coming from AuthenticatedFlyoutSwitch)
 *
 * from {...profileData} argument, provided by AuthenticatedFlyoutSwitch
 * @param {string} props.first_name
 * @param {string} props.last_name
 *
 * @return {JSX} ...
 */
const AuthenticatedFlyout = ({ isResPath, disableNavigation, first_name, last_name }) => {
  const dispatch = useDispatch();
  const breakpoint = useSelector(breakpointSelector);

  const [expanded, setExpanded] = useState(false);
  const buttonRef = useRef();
  const navContainerRef = useRef();

  const handleLogoutAction = () => dispatch(logout());
  const handleOpenLogoutModal = () => dispatch(openModal(MODAL.RESFLOW_LOGOUT_MODAL));

  const handleExpand = () => {
    setExpanded(true);
    utils.analytics.interaction('menu', 'nav', 'user');
  };

  const handleCollapse = (noAnalytics) => {
    setExpanded(false);
    const btn = buttonRef.current;
    btn && btn.focus();
    !noAnalytics && utils.analytics.interaction('menu', 'nav', 'user');
  };

  const handleToggleExpand = () => {
    expanded ? handleCollapse() : handleExpand();
  };

  const handleClickOutside = (e) => {
    // Guard against missing event object
    if (!e || !e.target) return;

    // Don't close the menu if clicking the toggle button
    if (expanded && !buttonRef.current.contains(e.target)) {
      if (e.target.classList.contains('header-flyout__list-item__link')) {
        utils.analytics.interaction('menu', 'nav', e.target.innerText.toLowerCase(), 'user');
      }
      setExpanded(false);
    }
  };

  const handleLogout = (e) => {
    handleClickOutside(e);
    handleLogoutAction();
    utils.analytics.interaction('menu', 'nav', e?.target?.innerText?.toLowerCase(), 'user');
  };

  const navItems = [
    {
      ItemComponent: Anchor,
      props: {
        href: `${utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.PROFILE_PAGE)}#${
          PROFILE.PROFILE_PATHS_CONFIG.myProfile
        }`,
        className: 'header-flyout__list-item__link link',
        onClick: handleClickOutside,
        role: 'menuItem',
        title: utils.i18n('logged_in_flyout_my_profile'),
      },
      itemText: utils.i18n('logged_in_flyout_my_profile'),
    },
    {
      ItemComponent: Anchor,
      props: {
        href: `${utils.config.getRedirectUrl(WINDOW_OBJECT_KEYS.PROFILE_PAGE)}#${PROFILE.PROFILE_PATHS_CONFIG.myTrips}`,
        className: 'header-flyout__list-item__link link',
        onClick: handleClickOutside,
        role: 'menuItem',
        title: utils.i18n('logged_in_flyout_my_trips'),
      },
      itemText: utils.i18n('logged_in_flyout_my_trips'),
    },
  ];

  const signOutNavItem = [
    {
      ItemComponent: Button,
      props: {
        onClick: handleLogout,
        link: true,
        className: 'header-flyout__list-item__link navItem',
      },
      itemText: utils.i18n('logged_in_flyout_sign_out'),
    },
  ];

  const resFlowNavItems = [
    {
      ItemComponent: Button,
      props: {
        onClick: handleOpenLogoutModal,
        link: true,
        className: 'header-flyout__list-item__link navItem',
      },
      itemText: utils.i18n('logged_in_flyout_sign_out'),
    },
  ];

  const getFirstName = () => first_name;

  const getNameInitials = () => `${first_name.charAt(0)}${last_name.charAt(0)}`;

  const handleKeyDown = (e) => {
    const key = utils.accessibility.formatKeys(e);
    const noAnalytics = true;
    // close the flyout on "Escape"
    key.escape && handleCollapse(noAnalytics);
  };

  const handleBlur = () => {
    // blur and focus events bubble up in React,
    // setTimeout gives a chance for the activeElement to update
    // if the activeElement is outside of navRef, close the dropdown
    // returns focus to the toggle button
    setTimeout(() => {
      const noAnalytics = true;
      !navContainerRef?.current?.contains(document.activeElement) && handleCollapse(noAnalytics);
    });
  };

  const getNavigationItems = () => {
    if (isResPath) {
      return resFlowNavItems;
    }
    if (disableNavigation) {
      return signOutNavItem;
    }
    return [...navItems, ...signOutNavItem];
  };

  useClickOutside(
    {
      container: navContainerRef,
      handler: handleClickOutside,
      listenerConditional: expanded,
    },
    [expanded]
  );

  return (
    <section className={cn('header-flyout header-flyout--authenticated', { 'header-flyout--in-resflow': isResPath })}>
      <div className='header-flyout__top'>
        <div className='header-flyout__top__text'>
          <div className='header-flyout__top__links'>
            <Button
              link
              className='header-flyout__top__toggle navItem'
              onClick={handleToggleExpand}
              buttonRef={buttonRef}
              ariaExpanded={expanded.toString()}
              ariaText={getFirstName()}
            >
              <div className='header-flyout__top__toggle__content'>
                <span
                  className={cn('header-flyout__top__name', {
                    expanded,
                  })}
                >
                  {breakpoint.isMobile ? getNameInitials() : getFirstName()}
                </span>
              </div>
            </Button>
          </div>
        </div>
      </div>

      <nav
        role='menu'
        className={cn('header-flyout__shadow-clipper', {
          expanded,
        })}
        aria-hidden={!expanded.toString()}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        ref={navContainerRef}
      >
        <ul className='header-flyout__content header-flyout__list component-theme--light'>
          {getNavigationItems().map(({ ItemComponent, props: itemProps, itemText }, idx) => (
            <li key={idx} className='header-flyout__list-item'>
              <ItemComponent {...itemProps}>{itemText}</ItemComponent>
            </li>
          ))}
        </ul>
      </nav>

      {isResPath && <ResflowLogoutModal />}
    </section>
  );
};

AuthenticatedFlyout.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  isResPath: PropTypes.bool,
  disableNavigation: PropTypes.bool,
};

export default AuthenticatedFlyout;
