import React, { Component } from 'react';
import cn from 'classnames';
import utils from 'utils';
import { ANALYTICS, RESERVATIONS } from 'constants';
import Button from 'components/Button';
import LoadingWidget from 'components/LoadingWidget';
import FormattedPrice from 'components/FormattedPrice';
import propTypes from './taxesFeesModalContentPropTypes';

export default class TaxesFeesModalContent extends Component {
  static propTypes = propTypes;

  state = { expanded: false };

  handleExpandDrawer = () => {
    const { taxesCopy, getTaxesPolicy } = this.props;
    this.setState((prevState) => ({ expanded: !prevState.expanded }));

    if (!taxesCopy) {
      getTaxesPolicy();
    }

    // Analytics - fire custom "drawer" event (push to interaction object)
    utils.analytics.interaction(
      ANALYTICS.DRAWER, // type
      ANALYTICS.TAXES_AND_FEES, // key
      this.state.expanded ? ANALYTICS.CLOSE : ANALYTICS.OPEN // value
    );
  };

  render() {
    const { feesArray, taxesFeesTotal, taxesCopy } = this.props;
    const { expanded } = this.state;
    // feesArray will either be [null, payLaterData] or [prepayData, payLaterData]
    // merge dataArrays if both exist
    let rows;
    let singleColHeader;
    let total;
    let isTwoCol;
    if (feesArray?.[0] && feesArray?.[1]) {
      isTwoCol = true;
      rows = utils.array.mergeTwo(feesArray[0], feesArray[1]);
    } else if (feesArray?.[0] && !feesArray?.[1]) {
      singleColHeader = utils.i18n('pre_pay_terms_modal_pre_pay_header').toUpperCase();
      rows = [...feesArray[0]];
      [total] = taxesFeesTotal;
    } else if (!feesArray?.[0] && feesArray?.[1]) {
      singleColHeader = utils.i18n('vehicle_select_pay_later').toUpperCase();
      rows = [...feesArray[1]];
      [, total] = taxesFeesTotal;
    }
    // 1 col layout
    return (
      <>
        {!isTwoCol ? (
          <div className='modal-themed__section taxes-fees-modal__list'>
            <table>
              <tbody>
                <tr>
                  <th />
                  <th className='col2 no-border'>{singleColHeader}</th>
                </tr>
                <tr className='taxes-fees-modal__row taxes-fees-modal__header-row'>
                  <th>{utils.i18n('vehicle_select_taxes_surcharges_fees').toUpperCase()}</th>
                  <th className='col2 no-border'>{total}</th>
                </tr>
                {rows &&
                  rows.map((row, ridx) => (
                    <tr key={`row ${ridx}`}>
                      <td>{row.description}</td>
                      <td className='col2 no-border'>
                        <FormattedPrice>
                          {row.status === RESERVATIONS.EXTRAS_INCLUDED_STATUS
                            ? utils.i18n('vehicle_select_extras_included').toUpperCase()
                            : utils.getFormattedPrice(row.total_amount_view)}
                        </FormattedPrice>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className='modal-themed__section taxes-fees-modal__list'>
            <table>
              <tbody>
                <tr>
                  <th />
                  <th className='col1'>{utils.i18n('pre_pay_terms_modal_pre_pay_header').toUpperCase()}</th>
                  <th className='col2'>{utils.i18n('vehicle_select_pay_later').toUpperCase()}</th>
                </tr>
                <tr className='taxes-fees-modal__row taxes-fees-modal__header-row'>
                  <th>{utils.i18n('vehicle_select_taxes_surcharges_fees').toUpperCase()}</th>
                  <th className='col1'>{taxesFeesTotal[0]}</th>
                  <th className='col2'>{taxesFeesTotal[1]}</th>
                </tr>

                {rows &&
                  rows.map((row, ridx) => (
                    <tr key={`row ${ridx}`}>
                      <td>{row[0].description}</td>
                      <td className='col1'>
                        <FormattedPrice>
                          {row[0].status === RESERVATIONS.EXTRAS_INCLUDED_STATUS
                            ? utils.i18n('vehicle_select_extras_included').toUpperCase()
                            : utils.getFormattedPrice(row[0].total_amount_view)}
                        </FormattedPrice>
                      </td>
                      <td className='col2'>
                        <FormattedPrice>
                          {row[1].status === RESERVATIONS.EXTRAS_INCLUDED_STATUS
                            ? utils.i18n('vehicle_select_extras_included').toUpperCase()
                            : utils.getFormattedPrice(row[1].total_amount_view)}
                        </FormattedPrice>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        <div className='modal-themed__drawer-container'>
          <Button
            button={false}
            className={cn('modal-themed__drawer', { 'modal-themed__drawer--expanded': expanded })}
            onClick={this.handleExpandDrawer}
            type='button'
            ariaExpanded={expanded.toString()}
          >
            {utils.i18n('taxes_fees_modal_drawer_title')}
          </Button>
        </div>

        {expanded &&
          (taxesCopy ? (
            <div
              className='modal-themed__section modal-themed__gbo-content'
              dangerouslySetInnerHTML={utils.sanitize(taxesCopy)}
            />
          ) : (
            <LoadingWidget />
          ))}
      </>
    );
  }
}
