import React, { useState, useRef } from 'react';
import useActions from 'hooks/useActions';
import utils from 'utils';
import { invalidateSession } from 'actions/request';
import { fetchCurrentSession } from 'actions/session';
import { MODAL, MODAL_BUTTON_TYPES, RESERVATIONS } from 'constants';
import { ModalFooter } from 'components/Modal/ModalFooter';
import ANALYTICS from 'constants/analytics';
import { getTimeRemainingForSessionTimeout, totalRemainingTime } from 'utils/date';
import { getSessionTimeoutChannel, timeoutRedirect } from 'utils/redirect';
import { showSessionTimeoutModal } from 'actions/modal/showSessionTimeoutModal';
import { SESSION_TIMEOUT_MODAL_CHANNEL_STATES } from 'constants/modals';

const { CONTINUE_WORKING } = SESSION_TIMEOUT_MODAL_CHANNEL_STATES;
const { ACCEPT } = MODAL_BUTTON_TYPES;

let invalidSession = false;

const TAB_ID_SESSION = utils.localStorage.getTabIdSession();
const sessionTimeoutChannel = getSessionTimeoutChannel();

function getRemainingTime(sessionTimeoutValue) {
  const { minutes, seconds } = getTimeRemainingForSessionTimeout(sessionTimeoutValue);
  const coundDownTimer = `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
  return coundDownTimer;
}

function getTimerState() {
  return JSON.parse(sessionStorage.getItem(`pauseTimer_${TAB_ID_SESSION}`));
}

const SessionTimeoutModalContent = ({ sessionTimeoutValue, handleClose, documentTitleState, tabInstanceId }) => {
  const [countDown, setCountDown] = useState(getRemainingTime(sessionTimeoutValue));
  const invalidateSessionAction = useActions(invalidateSession);
  const updateCurrentSession = useActions(fetchCurrentSession);
  const showTimeoutModal = useActions(() => showSessionTimeoutModal(true));

  const timeoutRef = useRef(null);

  const handleContinueWorking = () => {
    document.title = documentTitleState;
    clearInterval(timeoutRef.current);
    handleClose({ skipAnalytics: true });
    updateCurrentSession();

    const splitMinutes = countDown?.split(':');
    let timoueValue = 0;
    if (splitMinutes[0] || splitMinutes[1]) {
      timoueValue = splitMinutes[0] * 60 + utils.number.getIntegerNumber(splitMinutes[1]);
    }

    sessionStorage.setItem(`pauseTimer_${TAB_ID_SESSION}`, true);
    setTimeout(() => {
      sessionStorage.removeItem(RESERVATIONS.CURRENCY_TOGGLE_SELECTED);
    }, timoueValue);

    // start the timeout redirect again
    timeoutRedirect.start(() => showTimeoutModal());

    // Broadcast continue working to all tabs with sender ID
    sessionTimeoutChannel.postMessage({
      type: CONTINUE_WORKING,
      senderId: tabInstanceId,
    });
  };

  const startTimer = (endTime) => {
    let pauseState = getTimerState();
    function updateClock() {
      // To do later removing the pause state from here
      pauseState = getTimerState();
      const coundDownTimer = getRemainingTime(endTime);
      const total = totalRemainingTime(endTime);

      // invalidating session when the timer reaches 0
      if (total <= 0 && !invalidSession && !pauseState) {
        invalidSession = true;
        utils.timeoutRedirect.invalidateSession(invalidateSessionAction);
        document.title = documentTitleState;
        sessionStorage.removeItem(RESERVATIONS.CURRENCY_TOGGLE_SELECTED);
      }
      // updating timer logic locally
      if (utils.gmi.getCurrentDateTimeObj().isSameOrBefore(endTime) && !pauseState) {
        setCountDown(coundDownTimer);
        const splitMinutes = coundDownTimer.split(':');
        const convertMinutes = splitMinutes[0].replaceAll('0', '');
        document.title = Math.sign(convertMinutes) === -1 ? '00:00' : coundDownTimer;
      }
    }

    const id = setInterval(() => {
      if (!pauseState) {
        updateClock();
      }
    }, 1000);
    timeoutRef.current = id;
  };

  startTimer(sessionTimeoutValue);

  const splitMinutes = countDown.split(':');
  let convertMinutes = splitMinutes[0] === '00' ? '0' : splitMinutes[0].replaceAll('0', '');
  if (utils.number.getIntegerNumber(convertMinutes) >= 0) {
    convertMinutes = utils.number.getIntegerNumber(convertMinutes) + 1;
  }

  return (
    <React.Fragment>
      <p className='modal-themed__copy__msg'>{utils.i18n('reservation_session_timeout_info')}</p>
      <p role='timer' aria-label={`${splitMinutes[0]} min remaining`} className='modal-themed__copy__timer'>
        {Math.sign(convertMinutes) === -1 ? '00:00' : countDown}
      </p>
      <p
        role='timer'
        className='modal-themed__copy__aria-invisible'
        aria-live='assertive'
      >{`${convertMinutes} minutes remaining`}</p>
      <p className='modal-themed__copy__warning'>{utils.i18n('session_timeout_info')}</p>

      <ModalFooter
        buttons={[
          {
            type: ACCEPT,
            label: utils.i18n('continue_working_button'),
            extraLargeButton: true,
            handler: () => handleContinueWorking(),
            analyticsHandler: () =>
              utils.analytics.interaction(
                ANALYTICS.MODAL,
                utils.analytics.prepareKey(MODAL.SESSION_TIMEOUT_MODAL),
                ANALYTICS.CONTINUE
              ),
          },
        ]}
        fullWidthButton
        sessionTimeoutButton
      />
    </React.Fragment>
  );
};

export default SessionTimeoutModalContent;
